import { inject } from '@angular/core';
import {
  getState,
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';
import { SelectChallengeService } from 'src/app/core/services/select-challenge.services';
import {
  ChallengesResponse,
  ChallengesState,
  PairChallenge,
} from './challenges-sub-challenges.model';

const initialSubChallenge = {
  name: '',
  description: '',
  isActive: false,
  isValid: false,
  id: '',
};

const initialState: ChallengesState = {
  initialChallenges: null,
  initialSubChallenge,
  initialChallengeResult: {
    value: initialSubChallenge,
    isError: false,
    errorMessage: null,
    validationFailures: null,
  },
};

export type ChallengesSubChallengesStoreType = InstanceType<
  typeof ChallengesSubChallengesStore
>;

export const ChallengesSubChallengesStore = signalStore(
  withState(initialState),
  withMethods(
    (store, selectChallengeService = inject(SelectChallengeService)) => ({
      loadActiveSubaAndChallenge: rxMethod<void>(
        pipe(
          switchMap(() => selectChallengeService.getActiveChallenges()),
          tap({
            next: (challenges: ChallengesResponse) => {
              patchState(store, {
                initialChallenges: challenges,
              });
            },
            error: (err: any) => {
              console.error(
                'An error occurred in getActiveSubaAndChallenge:',
                err,
              );
            },
          }),
        ),
      ),
      setInInitialChallenge: rxMethod<PairChallenge[]>(
        pipe(
          tap((pairChallenge) => {
            const challengesState = getState(store);
            const subChallengesMap = new Set(
              pairChallenge.map(
                ({ challengeName, subChallengeName }) =>
                  `${challengeName}-${subChallengeName}`,
              ),
            );
            challengesState.initialChallenges?.results.forEach((item) =>
              item.value.subChallenges?.forEach((subChallenge) => {
                subChallenge.isDisabled = subChallengesMap.has(
                  `${item.value.name}-${subChallenge.name}`,
                );
              }),
            );
            patchState(store, {
              initialChallenges: challengesState.initialChallenges,
            });
          }),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({ loadActiveSubaAndChallenge }) {
      loadActiveSubaAndChallenge();
    },
  }),
);
