import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  setAllRequiredCheckboxesSelected,
  setIsDesignApproved,
} from '../actions/required-action.actions';

export interface RequiredActionState {
  areAllRequiredCheckboxesSelected: boolean;
  isDesignApproved: boolean;
}

const initState: RequiredActionState = {
  areAllRequiredCheckboxesSelected: false,
  isDesignApproved: false,
};

const reducer = createReducer(
  initState,
  on(
    setAllRequiredCheckboxesSelected,
    (state, { areAllRequiredCheckboxesSelected }) =>
      produce(state, (draft) => {
        draft.areAllRequiredCheckboxesSelected =
          areAllRequiredCheckboxesSelected;
      }),
  ),
  on(setIsDesignApproved, (state, { isDesignApproved }) =>
    produce(state, (draft) => {
      draft.isDesignApproved = isDesignApproved;
    }),
  ),
);

export const requiredActionReducer = (
  state: RequiredActionState = initState,
  action: any,
) => reducer(state, action);
