import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PSL } from 'src/app/core/common/models/master-data/psl';
import { IArrayResponse } from '../../../common/models/general.model';
import { AdminFeature } from '../../models/admin.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PslSelectionService extends BaseService<PSL> {
  constructor() {
    super(AdminFeature.PSL_MANAGEMENT);
  }
  getActivePSLs(): Observable<PSL[]> {
    return this.http
      .get<IArrayResponse<PSL>>(this.baseUrl + this.apis['getActivePSLs'])
      .pipe(map((res) => res.results));
  }
}
