import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface IPagination {
  page: number;
  pageSize: number;
  sortField: string;
  sort: 'asc' | 'desc';
}

export interface IPagingResponse<T> extends IPagination {
  items: T[];
}

export interface IGeneralItem {
  id: string;
  inUsed?: boolean;
}

export interface IArrayResponse<T> {
  totalCount: number;
  results: T[];
}

export type CanDeactivateType =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}

export const canDeactivateWorkflowGuard: CanDeactivateFn<
  CanComponentDeactivate
> = (component: CanComponentDeactivate) => {
  return component.canDeactivate ? component.canDeactivate() : true;
};

export const toCamelCase = (name: string) => {
  return name
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};
