import { Injectable } from '@angular/core';
import { DiagnosisHistory } from 'src/app/core/common/models/workflow/diagnosis-history';
import { DiagnosisSession } from 'src/app/core/common/models/workflow/diagnosis-session';
import { IArrayResponse } from '../../../common/models/general.model';
import { AdminFeature } from '../../models/admin.model';
import { BaseService } from '../master-data/base.service';

@Injectable({
  providedIn: 'root',
})
export class ManageSessionService extends BaseService<DiagnosisSession> {
  constructor() {
    super(AdminFeature.SESSION_MANAGEMENT);
  }

  block(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl + this.apis['block'].replace(':id', sessionId),
    );
  }

  unblock(sessionId: string) {
    return this.http.post<DiagnosisSession>(
      this.baseUrl + this.apis['unblock'].replace(':id', sessionId),
      { sessionId },
    );
  }

  cancel(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl + this.apis['cancel'].replace(':id', sessionId),
    );
  }

  restore(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl + this.apis['restore'].replace(':id', sessionId),
    );
  }

  getSessionHistory(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisHistory>>(
      this.baseUrl + this.apis['getSessionHistory'].replace(':id', sessionId),
    );
  }
}
