<section [ngClass]="{ 'overflow-hidden': isInitializeDiagnosisScreen() }">
  <div class="header">
    <app-header />
  </div>
  <div class="wrapper flex-1 overflow-auto flex flex-column">
    <div class="step-master">
      <app-step-master />
    </div>
    <div class="flex-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
