import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { DialogCommonService } from '../../services/common/dialog-common.service';
import { RoleService } from '../services/authorization/role.service';

export const adminGuard: CanActivateFn = () => {
  const roleService = inject(RoleService);
  const router = inject(Router);
  const dialogCommonService = inject(DialogCommonService);

  return roleService.getAssignedRoles().pipe(
    map((res) => {
      const adminRoles = res.filter((r) => r.isAdmin === true);
      if (adminRoles.length) {
        return true;
      }
      router.navigate(['/']);
      dialogCommonService.show(true, {
        title: 'Unauthorized',
        message: `You cannot enter this area.`,
        closeLabel: 'Close',
        closable: false,
      });
      return false;
    }),
  );
};
