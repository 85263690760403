import { AdminFeature } from './core/admin-features/models/admin.model';

export const apiConfig = {
  masterData: {
    getActiveChallenges: '/master/challenge/active',
    getChallengesByPSL: `/master/challenge/psl/:id`,
  },
  diagnosisSession: {
    initialize: '/session/initialize/:externalId',
    restart: '/session/restart/:sessionId',
    inprogress: '/session/inprogress/:sessionId',
    complete: '/session/complete/:sessionId',
    approve: '/session/approve/:sessionId',
    back: `/session/back/:sessionId`,

    getById: '/session/:sessionId',
    getByExternalId: '/session/:externalId',
    getByCurrentUser: '/session/current-user',
    getSessionChallenges: '/session/:sessionId/challenges',
  },
  diagnosisDataEntry: {
    upsertEntryValue: '/session/entry-value/upsert',
    changeUoM: '/session/entry-value/change/uom',
    removeEntryValue: '/session/entry-value/remove',
    resetEntryValue: '/session/:sessionId/entry-value/reset',
    verifyEnteredValues:
      '/session/:sessionId/entry-value/entered-values/verify',
    getEnteredValues: '/session/:sessionId/entry-value/entered-values',

    getSuggestedDataEntryCategories: '/session/:sessionId/entry-categories',
  },
  diagnosisSolution: {
    selectSolution: '/session/solution/add',
    unselectSolution: '/session/solution/remove',
    resetSolutionSelection: '/session/:sessionId/solution/reset',

    getAvailableSolutions: '/session/:sessionId/available-solutions',
    getSelectedSolutions: '/session/:sessionId/selected-solutions',
    getCombinedCompatibilities: '/session/:sessionId/solution-compatibilities',
  },
  diagnosisMetadata: {
    getSuggestedRequiredActions: '/session/:sessionId/suggested-actions',
    attachedSuggestedRequiredActions:
      '/session/:sessionId/suggested-action/attach',
    confirmRequiredAction: '/session/attached-action/update',
    getAttachedRequiredActions: '/session/:sessionId/attached-actions',
    getDiagnosisReferences: '/session/:sessionId/references',
  },
  userSetting: {
    applyUoMSetting: '/setting/uom/apply',
    getCurrentUoMSetting: '/setting/uom/detail',
  },
};

export const masterDataApi = {
  general: {
    statistic: '/general/statistic',
  },
};

export const AdminFeatureApis: {
  [key in AdminFeature]?: { [key: string]: string };
} = {
  [AdminFeature.ROLE_MANAGEMENT]: {
    getAllPermissions: '/authorization/permissions',
    getAllRoles: '/authorization/roles',
    getAssingedRoles: '/authorization/roles/assigned',
    getAssingedPermissions: '/authorization/permissions/assigned',
    updateRolesWithDetails: '/authorization/roles/update',

    add: '/authorization/role/add',
    update: '/authorization/role/update',
    delete: '/authorization/role/delete/:id',

    getAll: '/authorization/roles/detail',
  },
  [AdminFeature.PSL_MANAGEMENT]: {
    add: '/master/psl/add',
    update: '/master/psl/edit',
    delete: '/master/psl/delete/:id',

    getById: '/master/psl/detail/:id',
    getAll: '/master/psl/all',
    getActivePSLs: '/master/psl/active',
  },
  [AdminFeature.CHALLENGE_MANAGEMENT]: {
    add: '/master/challenge/add',
    update: '/master/challenge/edit',
    delete: `/master/challenge/delete/:id`,

    getById: `/master/challenge/detail/:id`,
    getAll: '/master/challenge/all',
    order: '/master/challenge/all/order',
  },
  [AdminFeature.ENTRY_CATEGORY_MANAGEMENT]: {
    add: '/master/entry/category/add',
    update: '/master/entry/category/edit',
    delete: `/master/entry/category/delete/:id`,

    getById: `/master/entry/category/detail/:id`,
    getAll: '/master/entry/category/all',
    getEntryJobPhases: `/master/entry/category/:id/jobPhases`,
    getJobPhaseValues: `/general/jobPhase/values`,
    getDropdownCategories: '/master/entry/category/dropdowns',

    getInputTypes: '/master/entry/category/inputs',
  },
  [AdminFeature.ENTRY_VALUE_MANAGEMENT]: {
    add: '/master/entry/value/add',
    update: '/master/entry/value/edit',
    delete: `/master/entry/value/delete/:id`,

    getById: `/master/entry/value/detail/:id`,
    getAll: '/master/entry/value/all',
    order: '/master/entry/value/all/order',
    getCompatibilitiesByEntryValueId: `/master/entry/value/:id/compatibilities`,
    getCompatibilityValues: '/general/compatibility/values',
  },
  [AdminFeature.SUB_CHALLENGE_MANAGEMENT]: {
    add: '/master/sub-challenge/add',
    update: '/master/sub-challenge/edit',
    delete: `/master/sub-challenge/delete/:id`,
    getById: `/master/sub-challenge/detail/:id`,
    getAll: '/master/sub-challenge/all',
    order: '/master/sub-challenge/all/order',
  },
  [AdminFeature.SOLUTION_RELEVANCE_LISTING]: {
    getSolutionRelevances: `/master/sub-challenge/solution/:id/relevances`,
  },
  [AdminFeature.SESSION_MANAGEMENT]: {
    getAll: '/session/all',
    getById: '/session/:id',

    getSessionHistory: `/session/:id/history`,
    restart: `/session/restart/:id`,
    cancel: `/session/cancel/:id`,
    restore: `/session/restore/:id`,
    block: `/session/block/:id`,
    unblock: `/session/unblock/:id`,
  },
  [AdminFeature.SOLUTION_CATEGORY]: {
    getAll: '/master/solution/category/all',
    add: '/master/solution/category/add',
    update: '/master/solution/category/edit',
    delete: `/master/solution/category/delete/:id`,
    getById: `/master/solution/category/detail/:id`,
  },
  [AdminFeature.SOLUTION_CONDITION]: {
    getAll: '/master/solution/condition/all',
    add: '/master/solution/condition/add',
    update: '/master/solution/condition/edit',
    delete: `/master/solution/condition/delete/:id`,
    getById: `/master/solution/condition/detail/:id`,
    getUoMCategories: '/master/solution/condition/uom/categories',
  },
  [AdminFeature.SOLUTION_MANAGEMENT]: {
    getAll: '/master/solution/all',
    add: '/master/solution/add',
    update: '/master/solution/edit',
    delete: `/master/solution/delete/:id`,
    getById: `/master/solution/detail/:id`,
    order: '/master/solution/all/order',
    getSolutionCompatibilities: `/master/solution/:id/compatibilities`,
    getSolutionGateActions: `/master/solution/:id/gates`,
    getSolutionConditions: `/master/solution/:id/limits`,
    getSolutionReferences: `/master/solution/:id/references`,
  },
  [AdminFeature.REFERENCE_CATEGORY]: {
    add: '/master/reference/category/add',
    update: '/master/reference/category/edit',
    delete: `/master/reference/category/delete/:id`,

    getById: `/master/reference/category/detail/:id`,
    getAll: '/master/reference/category/all',
  },
  [AdminFeature.GATE_ACTION_CATEGORY]: {
    getAll: '/master/gateAction/category/all',
    add: '/master/gateAction/category/add',
    update: '/master/gateAction/category/edit',
    delete: `/master/gateAction/category/delete/:id`,
    getById: `/master/gateAction/category/detail/:id`,
  },
  [AdminFeature.GATE_ACTION]: {
    getAll: '/master/gateAction/all',
    add: '/master/gateAction/add',
    update: '/master/gateAction/edit',
    delete: `/master/gateAction/delete/:id`,
    getById: `/master/gateAction/detail/:id`,
  },
  [AdminFeature.REFERENCE]: {
    add: '/master/reference/add',
    update: '/master/reference/edit',
    delete: `/master/reference/delete/:id`,

    getById: `/master/reference/detail/:id`,
    getAll: '/master/reference/all',
    order: '/master/reference/all/order',
    getSolutionReferences: `/master/reference/:id/solutions`,
  },
};
