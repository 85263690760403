<div class="action">
  <h2 class="action-header">Required Actions</h2>
  @if (diagnosisSession?.diagnosisStatusName !== status.APPROVED_FOR_DESIGN) {
    <p-message
      severity="info"
      icon="fa-solid fa-circle-info"
      text="Please check each box to acknowledge that the required action has been taken, or the special consideration has been taken into account."
      variant="outlined"
      styleClass="message-guide"
    />
  }
  <div class="overflow-auto">
    @for (section of actionsByCate; track $index) {
      <div class="action-checkbox">
        <div class="action-checkbox_title">{{ section.category }}</div>
        <form [id]="'form' + $index" class="action-checkbox_form" [formGroup]="formGroup">
          @for (item of section.gateActions; track $index) {
            <div
              class="action-checkbox_form-item"
              [ngClass]="{
                'border-bottom': $index < section.gateActions.length - 1 && isDesignApproved(),
                'design-approved': isDesignApproved(),
              }"
            >
              @if (!isDesignApproved() && diagnosisSession?.diagnosisStatusName !== status.APPROVED_FOR_DESIGN) {
                <input
                  type="checkbox"
                  [id]="item.gateAction.name"
                  class="checkbox center-flex"
                  (change)="onCheckboxChange(item)"
                  [checked]="item.isCompleted"
                />
              }
              <label [for]="item.gateAction.name" class="name">{{ item.gateAction.name }} ({{ item.relatedSolutions }})</label>
            </div>
          }
        </form>
      </div>
    }
  </div>
</div>
