import { Component, inject } from '@angular/core';

import { SolutionTitle } from 'src/app/core/common/enums/select-solution.enum';
import { ReviewSelectedChallangesStore } from './review-selected-challanges.store';

@Component({
  selector: 'app-review-selected-challanges',
  standalone: true,
  imports: [],
  providers: [ReviewSelectedChallangesStore],
  templateUrl: './review-selected-challanges.component.html',
  styleUrl: './review-selected-challanges.component.scss',
})
export class ReviewSelectedChallangesComponent {
  readonly store = inject(ReviewSelectedChallangesStore);
  title = SolutionTitle;
}
