import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { adminGuard } from './core/admin-features/guards/admin.guard';
import { AdminStore } from './core/admin-features/store/admin.store';
import { canDeactivateWorkflowGuard } from './core/common/models/general.model';
import { ADMIN_ROUTES } from './views/admin-features/admin.routes';
import { AdminLayoutComponent } from './views/layouts/admin-layout/admin-layout.component';
import { UserLayoutComponent } from './views/layouts/user-layout/user-layout.component';
import { RequiredActionsContainerComponent } from './views/required-actions/components/required-actions-container/required-actions-container.component';
import { SelectChallengesContainerComponent } from './views/select-challenge/components/select-challenges-container/select-challenges-container.component';
import { SelectSolutionsContainerComponent } from './views/select-solutions/components/select-solutions-container/select-solutions-container.component';
import { DiagnosisSummaryViewContainerComponent } from './views/view-summary/components/diagnosis-summary-view-container/diagnosis-summary-view-container.component';

export const routes: Routes = [
  {
    path: '',
    component: UserLayoutComponent,
    canActivate: [MsalGuard],
    canDeactivate: [canDeactivateWorkflowGuard],
    children: [
      { path: '', redirectTo: '/select-challenges', pathMatch: 'full' },
      {
        path: 'eid/:eid',
        component: SelectChallengesContainerComponent,
        pathMatch: 'full',
      },
      {
        path: 'sid/:sid',
        component: SelectChallengesContainerComponent,
        pathMatch: 'full',
      },
      {
        path: 'select-challenges',
        component: SelectChallengesContainerComponent,
        pathMatch: 'full',
      },
      {
        path: 'select-solutions/:sid',
        component: SelectSolutionsContainerComponent,
      },
      {
        path: 'view-summary/:sid',
        component: DiagnosisSummaryViewContainerComponent,
      },
      {
        path: 'required-actions/:sid',
        component: RequiredActionsContainerComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    providers: [AdminStore],
    children: ADMIN_ROUTES,
    canActivate: [adminGuard, MsalGuard],
  },
];
