import { createSelector } from '@ngrx/store';
import { RootState } from '../reducers/RootState.model';
import { RequiredActionState } from '../reducers/required-action.reducer';

export const requiredAction = (state: RootState) => state.requiredAction;

export const getAllRequiredCheckboxesSelected = createSelector(
  requiredAction,
  (state: RequiredActionState) => state?.areAllRequiredCheckboxesSelected,
);

export const getIsDesignApproved = createSelector(
  requiredAction,
  (state: RequiredActionState) => state?.isDesignApproved,
);
