<ul class="container">
  <li class="logo">
    <a href="/">
      <img class="logo" ngSrc="assets/halliburton.svg" alt="halliburton-logo" width="120" height="40" />
    </a>
  </li>
  <li class="name">
    <span>HPS 3D WORKFLOW</span>
  </li>
  <li class="username grid-item">
    <span>{{userName}} </span>
    <p-button icon="pi pi-sign-out" [rounded]="true" severity="info" [outlined]="true" (click)="logOut()" />
  </li>
  @if(isSelectSolutionScreen()) {
  <li class="grid-item">
    <p-button class="restart-btn" label="Restart Flow" (onClick)="restartWorkflow()" />
  </li>
  }
  <li class="initialize-grid-item">
    <p-button
      class="next-page-btn"
      [label]="isSelectSolutionScreen() ?  label.ViewSummary  : label.InitializeDiagnosis"
      (onClick)="navigateToNextScreen()"
      [disabled]="isSelectSolutionScreen() ? isDisableViewSummaryBtn() : isDisableInitializeBtn()"
      [ngClass]="{'not-disable-btn': isSelectSolutionScreen() ? !isDisableViewSummaryBtn() : !isDisableInitializeBtn()}"
    />
  </li>
</ul>
