import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  setAvailableSolutionSelectedFlag,
  setInputFields,
  setIsSelectSolutionFlag,
} from '../actions/select-solution.action';

export interface InputState {
  isAllFieldSelected: boolean;
  isAvailableSolutionSelected: boolean;
  isIsSelectSolutionFlag: boolean;
}

const initState: InputState = {
  isAllFieldSelected: false,
  isAvailableSolutionSelected: false,
  isIsSelectSolutionFlag: false,
};

const reducer = createReducer(
  initState,
  on(setInputFields, (state, { isAllFieldSelected }) =>
    produce(state, (draft) => {
      draft.isAllFieldSelected = isAllFieldSelected;
    }),
  ),
  on(
    setAvailableSolutionSelectedFlag,
    (state, { isAvailableSolutionSelected }) =>
      produce(state, (draft) => {
        draft.isAvailableSolutionSelected = isAvailableSolutionSelected;
      }),
  ),
  on(setIsSelectSolutionFlag, (state, { isIsSelectSolutionFlag }) =>
    produce(state, (draft) => {
      draft.isIsSelectSolutionFlag = isIsSelectSolutionFlag;
    }),
  ),
);

export const selectSolutionReducer = (
  state: InputState = initState,
  action: any,
) => reducer(state, action);
