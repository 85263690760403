@let hasEnteredDataValues = store.enteredDataValues() && store.enteredDataValues().length;
<div class="input">
  <div class="input_title">
    <h2>{{ title.Inputs }}</h2>
  </div>

  @if (!store.enteredDataValues()) {
  <div class="solution-empty">
    <h3 class="no-data">No Data yet</h3>
    <span>Once required inputs are filled, you will see data here</span>
  </div>
  } @else { @defer (when (hasEnteredDataValues)) {
  <div class="input-info">
    @for(value of store.enteredDataValues(); track $index){
    <div class="input-info_row">
      <div class="inline-block input-info_key">{{ value.dataEntryCategoryName }}</div>
      <div class="inline-block input-info_value">{{ getEnteredValue(value) }}</div>
    </div>
    }
  </div>
  } @placeholder {
  <p-progressSpinner ariaLabel="loading" class="center-flex" />
  } }
</div>
