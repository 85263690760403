export enum SolutionTitle {
  Title = 'Available Solutions',
  Inputs = 'Inputs',
  SelectedChallenges = 'Selected Challenges',
}

export enum ButtonLabels {
  InitializeDiagnosis = 'Initialize Diagnosis',
  ViewSummary = 'View Summary',
  CompleteDiagnosis = 'Complete Diagnosis',
  ApprovedForDesign = 'Approved For Design',
}

export enum SelectInputSolutionFormKey {
  InputInteger = 'InputInteger',
  InputDouble = 'InputDouble',
  DropDown = 'DropDown',
}

export enum AvailableSolutionBlockTitle {
  Tools = 'Tools',
  Chemicals = 'chemicals',
  Conveyance = 'conveyance',
}

export enum SolutionColor {
  Synergy = 'Tools',
  Chemicals = 'chemicals',
  Conveyance = 'conveyance',
}

export enum CompatibilityValueTypes {
  NotRelevant = 'Not Relevant',
  NotCompatible = 'not compatible',
  NotIdeal = 'not ideal',
  Compatible = 'compatible',
  AddsSynergy = 'adds synergy',
}

export enum SummarySectionTypes {
  SolutionMessage = 'Warning',
  GateAction = 'Gate Actions',
  Reference = 'References',
}
