export enum SolutionTitle {
  Title = 'Available Solutions',
  Inputs = 'Inputs',
  SelectedChallenges = 'Selected Challenges',
}

export enum ButtonLabels {
  InitializeDiagnosis = 'Initialize Diagnosis',
  ViewSummary = 'View Summary',
}

export enum SelectInputSolutionFormKey {
  FillType = 'fillType',
  ParticleType = 'particleType',
  Filllocation = 'fillLocation',
  WellboreDeviation = 'wellboreDeviation',
  FluidInWellbore = 'fluidInWellbore',
  DownholeTemperature = 'Downhole Temperature',
  DegreeOfDepletion = 'degreeOfDepletion',
  Formation = 'formation',
}

export enum AvailableSolutionBlockTitle {
  Tools = 'Tools',
  Chemicals = 'chemicals',
  Conveyance = 'conveyance',
}
