<ul class="container">
  <li class="logo">
    <a href="/">
      <img class="logo" ngSrc="assets/halliburton.svg" alt="halliburton-logo" width="120" height="40" />
    </a>
  </li>
  <li class="name">
    <span>3D WORKFLOW</span>
  </li>
  <li class="username grid-item">
    <span class="user-name">{{userName}} </span>
    <p-button icon="pi pi-sign-out" class="sign-out-icon" [rounded]="true" severity="info" [outlined]="true" (click)="logOut()" />
  </li>

  @if(isSelectSolutionScreen() || isViewSummaryScreen()) {
  <li class="grid-item">
    <p-button class="restart-btn" (onClick)="restartWorkflow()">
      <div class="restart-btn_title">Restart Flow</div>
      <i class="fa fa-refresh restart-btn_icon" aria-hidden="true"></i>
    </p-button>
  </li>
  } @if(!isInitializeDiagnosisScreen()) {
  <li class="grid-item status" [ngClass]="getTagClassName(this.diagnosisSessionStatus)">
    <div class="status-tag">{{this.diagnosisSessionStatus}}</div>
  </li>
  }

  <li class="grid-item back">
    @if(isViewSummaryScreen() || (this.isRequiredActionScreen() && !isHideApprovedForDesignBtn())) {
    <p-button class="back-btn" (onClick)="backToPreviousScreen()">
      <div class="back-btn_title">Back</div>
      <i class="fa fa-arrow-left back-btn_icon" aria-hidden="true"></i>
    </p-button>
    }
  </li>

  <li class="initialize-grid-item">
    @if(!isHideApprovedForDesignBtn()) {
    <p-button
      class="next-page-btn"
      [label]="setNextStepButtonLabel()"
      (onClick)="navigateToNextScreen()"
      [disabled]="isNextBtnDisabled()"
      [ngClass]="{
      'not-disable-btn': isSelectSolutionScreen()
        ? !isDisableViewSummaryBtn()
        : isRequiredActionScreen()
        ? !isDisableApprovedForDesignBtn()
        : !isDisableInitializeBtn()
    }"
    />
    }
  </li>
</ul>
