import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

import { apiConfig } from '../../../../api.config';
import {
  IUoMSetting,
  IUserSetting,
} from '../../../common/models/workflow/user-setting';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  applyUoMSetting(item: {
    username: string;
    uoMSettings: Pick<IUoMSetting, 'uoMcategoryId' | 'selectedUoMId'>[];
  }): Observable<IUserSetting> {
    return this.http.post<IUserSetting>(
      this.baseUrl + apiConfig.userSetting.applyUoMSetting,
      item,
    );
  }

  getCurrentUoMSetting(): Observable<IUserSetting> {
    return this.http.get<IUserSetting>(
      this.baseUrl + apiConfig.userSetting.getCurrentUoMSetting,
    );
  }
}
