import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import {
  setChallenges,
  setExternalId,
  setScreenStates,
  setSessionId,
} from '../actions/select-challenge.actions';

export interface ChallengeState {
  pairChallenges: PairChallenge[];
  isInitializeDiagnosisScreen: boolean;
  diagnosisSessionId: string;
  diagnosisExternalId: string;
}

const initState: ChallengeState = {
  pairChallenges: [],
  isInitializeDiagnosisScreen: false,
  diagnosisSessionId: '',
  diagnosisExternalId: '',
};

const reducer = createReducer(
  initState,
  on(setChallenges, (state, { pairChallenges }) =>
    produce(state, (draft) => {
      draft.pairChallenges = pairChallenges;
    }),
  ),
  on(setScreenStates, (state, { isInitializeDiagnosisScreen }) =>
    produce(state, (draft) => {
      draft.isInitializeDiagnosisScreen = isInitializeDiagnosisScreen;
    }),
  ),
  on(setSessionId, (state, { diagnosiSessionId }) =>
    produce(state, (draft) => {
      draft.diagnosisSessionId = diagnosiSessionId;
    }),
  ),
  on(setExternalId, (state, { diagnosiExternalId }) =>
    produce(state, (draft) => {
      draft.diagnosisExternalId = diagnosiExternalId;
    }),
  ),
);

export const challengesReducer = (
  state: ChallengeState = initState,
  action: any,
) => reducer(state, action);
