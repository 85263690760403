<div class="container">
  <div class="challenge">
    <div class="challenge_content-left">
      <h2 class="challenge_title">Selected Challenges</h2>
    </div>
    <div class="challenge_content-right" (click)="removeAllPairs()">
      <i class="pi pi-times cursor-pointer" [ngClass]="{'not-disable-btn': handleDisable()}"></i>
      <p-button label="Clear All" class="challenge_input" [disabled]="!handleDisable()" [ngClass]="{'not-disable-btn': handleDisable()}"></p-button>
    </div>
  </div>

  @if(pairChallenges().length) {
  <div class="pairs-wrapper">
    <div class="pairs-wrapper_total">Total: {{pairChallenges().length}} Item(s)</div>
    <div class="pairs-wrapper_content">
      @for(pairChallenge of pairChallenges(); track pairChallenge.challengeName){
      <div class="pairs-content">
        <div class="pairs-content_text">
          <span>{{pairChallenge.challengeName}} - {{pairChallenge.subChallengeName}}</span>
        </div>
        <div class="pairs-content_icon" (click)="pairChallenge.subChallengeName && removePairChallenge(pairChallenge.subChallengeName)">
          <i class="pi pi-times cursor-pointer circle"></i>
        </div>
      </div>
      }
    </div>
  </div>
  } @else {
  <div class="challenge_content-center">
    <h3 class="title">No Data yet</h3>
    <span>Once a challenge is selected, you will see data here</span>
  </div>
  }
</div>
