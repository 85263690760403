<div class="modal-container" [appLoading]="!userSetting().uoMSettings.length">
  <div class="action-info">
    <i class="fa-solid fa-circle-info action-info_icon inline-block font-size-10"></i>
    <span class="action-info_message inline-block font-size-10">Define your default units of measure</span>
  </div>
  @if (userSetting().uoMSettings.length && form) {
    <form [formGroup]="form" class="input-form max-h-25rem overflow-auto -mx-4 px-4">
      @for (setting of userSetting().uoMSettings; track setting.uoMcategoryId) {
        <div class="field">
          <label [for]="setting">{{ setting.uoMCategoryName }}:</label>
          <p-select
            appendTo="body"
            [formControlName]="setting.key!"
            [options]="setting.options"
            optionLabel="label"
            optionValue="value"
            placeholder="Choose a unit"
            styleClass="custom-select w-full"
          />
        </div>
      }
    </form>
  }

  <div class="flex align-items-center justify-content-between">
    <div class="col-6 p-0">
      <p-button label="Reset To Base Unit" styleClass="btn-outlined-danger" (click)="resetToDefault()" />
    </div>
    <div class="col-6 p-0 flex align-items-center justify-content-end">
      <p-button label="Cancel" styleClass="btn-outlined-dark" (click)="close()" />
      <p-button label="Apply" styleClass="btn-contrast ml-2" (click)="apply()" [disabled]="form?.pristine || form?.invalid" />
    </div>
  </div>
</div>
