import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { navigateToNextStep } from '../actions/router.action';

@Injectable()
export class RouterEffects {
  readonly #actions = inject(Actions);
  readonly #router = inject(Router);

  navigateToNextStep$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(navigateToNextStep),
        tap(({ pageRoute, sessionId, externalId }) => {
          const navigationParams = [pageRoute];
          if (externalId) navigationParams.push(externalId);
          if (sessionId) navigationParams.push(sessionId);
          this.#router.navigate(navigationParams);
        }),
      ),
    { dispatch: false },
  );
}
