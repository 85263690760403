import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { Toast } from 'primeng/toast';
import { filter } from 'rxjs';
import { MsalServiceInitializer } from './core/services/msal-initializer.service';
import { selectScreenState } from './core/store/selectors/select-challenge.selectors';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, Toast],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);

  title = '3d-worflow-webapp';
  loginDisplay = false;
  loginProcess = false;
  loadingProcess = true;
  isInitializeDiagnosisScreen = signal(false);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private msalInitializerService: MsalServiceInitializer,
  ) {}

  async ngOnInit() {
    await this.msalInitializerService.initialize();
    this.initialAuth();
    this.getScreenState();
  }

  initialAuth(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.setLoginDisplay();
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
    if (!this.loginDisplay) {
      this.loginRedirect();
    }
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    this.loadingProcess = false;
    this.loginProcess = false;
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  getScreenState(): void {
    this.#globalStore
      .select(selectScreenState)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isInitializeDiagnosisScreen.set(res);
      });
  }
}
