import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  type OnInit,
} from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import {
  ChallengeName,
  ChallengeType,
} from 'src/app/core/common/enums/select-challenge.enum';
import { SortAlphabetPipe } from 'src/app/core/common/pipe/sort-alphabet.pipe';
import { LOCAL_VARS } from '../../../../../core/common/constants/routes.constant';
import type { PSL } from '../../../../../core/common/models/master-data/psl';
import { DialogCommonService } from '../../../../../core/services/common/dialog-common.service';
import { ChallengeListComponent } from './challenge-list/challenge-list.component';
import {
  CommonChallenge,
  SubChallenge,
} from './challenges-sub-challenges.model';
import { ChallengesSubChallengesStore } from './challenges-sub-challenges.store';

@Component({
  selector: 'app-challenges-sub-challenges',
  standalone: true,
  imports: [
    ButtonModule,
    NgClass,
    TableModule,
    ChallengeListComponent,
    SortAlphabetPipe,
  ],
  templateUrl: './challenges-sub-challenges.component.html',
  styleUrl: './challenges-sub-challenges.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengesSubChallengesComponent implements OnInit {
  readonly store = inject(ChallengesSubChallengesStore);
  challengeType = ChallengeType;
  challengeName = ChallengeName;
  selectedChallenge = signal<CommonChallenge | null>(
    this.store.initialChallengeResult(),
  );
  selectedSubChallenge = signal<SubChallenge | null>(
    this.store.initialSubChallenge(),
  );
  dialogCommonService = inject(DialogCommonService);

  ngOnInit(): void {
    this.checkPSLSelection();
  }

  handleChallengeSelection(
    selectedChallenge: CommonChallenge | SubChallenge,
    challengeType: string,
  ): void {
    if (challengeType === ChallengeType.Challenge) {
      const challenge = selectedChallenge as CommonChallenge;
      this.selectedChallenge.set(challenge);
      this.store.setSelectedSubChallenges(challenge.subChallenges || []);
    } else this.selectedSubChallenge.set(selectedChallenge as SubChallenge);
  }

  addPairChallenge(): void {
    if (this.selectedChallenge() && this.selectedSubChallenge()) {
      this.store.addPairChallenge({
        challengeName: this.selectedChallenge()!.name,
        subChallengeId: this.selectedSubChallenge()!.id,
        subChallengeName: this.selectedSubChallenge()!.name,
      });
      this.selectedSubChallenge.set(null);
    }
  }

  private checkPSLSelection() {
    const pslValue = localStorage.getItem(LOCAL_VARS.SelectedPSL);
    if (pslValue) {
      const selectedPSL: PSL = JSON.parse(pslValue);
      this.store.setSelectedPSL(selectedPSL);
      this.store.loadChallengeByPSL(selectedPSL.id);
    } else {
      this.openPSLSelection();
    }
  }

  private openPSLSelection() {
    this.dialogCommonService.openPSLSelection().subscribe((psl: PSL) => {
      if (psl) {
        localStorage.setItem(
          LOCAL_VARS.SelectedPSL,
          JSON.stringify({ name: psl.name, id: psl.id }),
        );
        this.store.setSelectedPSL(psl);
        this.store.loadChallengeByPSL(psl.id);
      }
    });
  }
}
