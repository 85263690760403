import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { ChallengesResponse } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectChallengeService {
  #http = inject(HttpClient);
  private apiUrl = environment.api;

  getActiveChallenges(): Observable<ChallengesResponse> {
    return this.#http.get<ChallengesResponse>(
      this.apiUrl + apiConfig.masterData.getActiveChallenges,
    );
  }

  getChallengesByPSL(pslId: string): Observable<ChallengesResponse> {
    return this.#http.get<ChallengesResponse>(
      this.apiUrl +
        apiConfig.masterData.getChallengesByPSL.replace(':id', pslId),
    );
  }
}
