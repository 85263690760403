import { Component, inject, input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewInputInformationsComponent } from 'src/app/core/common/components/review-input-informations/review-input-informations.component';
import { ReviewSelectedChallangesComponent } from 'src/app/core/common/components/review-selected-challanges/review-selected-challanges.component';
import { ReviewSelectedSolutionsComponent } from 'src/app/core/common/components/review-selected-solutions/review-selected-solutions.component';
import { REQUEST_PARAM } from 'src/app/core/common/constants/routes.constant';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { DiagnosisSummaryViewComponent } from './diagnosis-summary-view/diagnosis-summary-view.component';

@Component({
  selector: 'app-diagnosis-summary-view-container',
  standalone: true,
  imports: [
    ReviewSelectedChallangesComponent,
    ReviewInputInformationsComponent,
    ReviewSelectedSolutionsComponent,
    DiagnosisSummaryViewComponent,
  ],
  templateUrl: './diagnosis-summary-view-container.component.html',
  styleUrl: './diagnosis-summary-view-container.component.scss',
})
export class DiagnosisSummaryViewContainerComponent implements OnInit {
  activeRoute = inject(ActivatedRoute);
  storeService = inject(StoreService);
  sid = input.required<string>();

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      const sessionId = params.get(REQUEST_PARAM.SessionId) ?? '';
      this.storeService.dispatchSessionId(sessionId);
    });
  }
}
