import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { REQUEST_PARAM } from 'src/app/core/common/constants/routes.constant';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { AvailableSolutionsComponent } from './available-solutions/available-solutions.component';
import { InputSolutionsComponent } from './input-solutions/input-solutions.component';

@Component({
  selector: 'app-select-solutions-container',
  standalone: true,
  imports: [InputSolutionsComponent, AvailableSolutionsComponent],
  templateUrl: './select-solutions-container.component.html',
  styleUrl: './select-solutions-container.component.scss',
})
export class SelectSolutionsContainerComponent implements OnInit {
  readonly #activeRoute =
    inject(ActivatedRoute).paramMap.pipe(takeUntilDestroyed());
  readonly #storeService = inject(StoreService);

  ngOnInit(): void {
    this.#activeRoute.subscribe((params) => {
      const sessionId = params.get(REQUEST_PARAM.SessionId);
      if (sessionId) this.#storeService.dispatchSessionId(sessionId);
    });
  }
}
