import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AdminFeatureApis } from '../../../../api.config';
import {
  IArrayResponse,
  IGeneralItem,
  IPagination,
  IPagingResponse,
} from '../../../common/models/general.model';
import {
  AdminFeature,
  IAdminService,
  IOrderByItem,
} from '../../models/admin.model';

@Injectable({
  providedIn: 'root',
})
export class BaseService<T extends IGeneralItem> implements IAdminService {
  protected http = inject(HttpClient);
  baseUrl = environment.api;
  feature: AdminFeature;
  apis: { [key: string]: string };

  constructor(feature: AdminFeature) {
    this.feature = feature;
    this.apis = AdminFeatureApis[this.feature] || {};
  }
  getList(_pagination: IPagination): Observable<IPagingResponse<T>> {
    throw new Error('Method not implemented.');
  }
  getAllItems(): Observable<T[]> {
    this.verifyApiByKey('getAll');
    return this.http
      .get<IArrayResponse<T>>(this.baseUrl + this.apis['getAll'])
      .pipe(map((res) => res.results));
  }
  getItemDetail(selectedId: string): Observable<T> {
    this.verifyApiByKey('getById');
    return this.http.get<T>(
      this.baseUrl + this.apis['getById'].replace(':id', selectedId),
    );
  }
  addItem(item: T): Observable<T> {
    this.verifyApiByKey('add');
    return this.http.post<T>(this.baseUrl + this.apis['add'], item);
  }
  updateItem(item: T): Observable<T> {
    this.verifyApiByKey('update');
    return this.http.post<T>(this.baseUrl + this.apis['update'], item);
  }
  deleteItem(id: string): Observable<T> {
    this.verifyApiByKey('delete');
    return this.http.delete<T>(
      this.baseUrl + this.apis['delete'].replace(':id', id),
    );
  }
  orderItems(items: IOrderByItem[]): Observable<T[]> {
    this.verifyApiByKey('order');
    return this.http
      .post<IArrayResponse<T>>(this.baseUrl + this.apis['order'], items)
      .pipe(map((res) => res.results));
  }

  private verifyApiByKey(key: string) {
    if (!this.apis[key])
      throw new Error(`${this.feature} does not support ${key} api.`);
  }
}
