<div class="selected-challenges">
  <h2 class="selected-challenges_header">{{ title.SelectedChallenges }}</h2>
  @if (store.selectedChallenges() && store.selectedChallenges().length) {
    <div class="selected-challenges-wrapper">
      @for (pairChallenge of store.selectedChallenges(); track pairChallenge.subChallengeName) {
        <div class="selected-challenges-content">
          <span>{{ pairChallenge.challengeName }} - {{ pairChallenge.subChallengeName }}</span>
        </div>
      }
    </div>
  }
</div>
