import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { combineLatest, filter, pipe, switchMap } from 'rxjs';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import { ReviewSelectedChallangesModel } from './review-selected-challanges.model';

const initialState: ReviewSelectedChallangesModel = {
  diagnosisSessionId: '',
  selectedChallenges: [],
};

export type ReviewSelectedChallangesStoreType = InstanceType<
  typeof ReviewSelectedChallangesStore
>;

export const ReviewSelectedChallangesStore = signalStore(
  withState(initialState),
  withMethods(
    (
      store,
      diagnosisSessionService = inject(DiagnosisSessionService),
      storeService = inject(StoreService),
    ) => ({
      getSessionIdAndPairChallenges: rxMethod<void>(
        pipe(
          switchMap(() =>
            combineLatest([
              storeService.getSessionId(),
              storeService.getPairChallenge(),
            ]),
          ),
          tapResponse(
            ([res, pairChallenges]: [string, PairChallenge[]]) => {
              patchState(store, {
                diagnosisSessionId: res,
                selectedChallenges: pairChallenges,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getSessionIdAndPairChallenges:',
                error,
              );
            },
          ),
        ),
      ),
      getSelectedChallenges: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSessionService.getSessionChallenges(diagnosisSessionId),
          ),
          tapResponse(
            (res) => {
              const filterdPairChallenges = res.results.map<PairChallenge>(
                (challenge) => {
                  return {
                    challengeName: challenge.subChallenge.challengeName,
                    subChallengeId: challenge.subChallenge.id,
                    subChallengeName: challenge.subChallenge.name,
                  };
                },
              );
              patchState(store, {
                selectedChallenges: filterdPairChallenges,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getSelectedChallenges:',
                error,
              );
            },
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({
      getSessionIdAndPairChallenges,
      getSelectedChallenges,
      diagnosisSessionId,
    }) {
      getSessionIdAndPairChallenges();
      getSelectedChallenges(diagnosisSessionId);
    },
  }),
);
