import { Pipe, PipeTransform } from '@angular/core';
import { ChallengeType } from '../enums/select-challenge.enum';

@Pipe({
  name: 'sortAlphabet',
  standalone: true,
})
export class SortAlphabetPipe implements PipeTransform {
  transform(value: any[], field: string): any[] {
    if (!value || !field) return value;

    if (field === ChallengeType.Challenge) {
      return value.sort((a, b) => a.value.name.localeCompare(b.value.name));
    }
    return value.sort((a, b) => a.name.localeCompare(b.name));
  }
}
