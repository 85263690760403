import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap } from 'rxjs';
import { DiagnosisDataEntryService } from 'src/app/core/services/diagnosis-data-entry.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { DiagnosisDataEntry } from '../../models/workflow/diagnosis-entry';
import { ReviewInputInformationsState } from './review-input-informations.model';

const initialState: ReviewInputInformationsState = {
  diagnosisSessionId: '',
  enteredDataValues: [],
};

export type ReviewInputInformationsStoreType = InstanceType<
  typeof ReviewInputInformationsStore
>;

export const ReviewInputInformationsStore = signalStore(
  withState(initialState),
  withMethods(
    (
      store,
      diagnosisDataEntryService = inject(DiagnosisDataEntryService),
      storeService = inject(StoreService),
    ) => ({
      getDiagnosisSessionId: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getSessionId()),
          tapResponse(
            (diagnosisSessionId: string) => {
              patchState(store, {
                diagnosisSessionId,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getDiagnosisSessionId:',
                error,
              );
            },
          ),
        ),
      ),
      loadEnteredValues: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisDataEntryService.getEnteredValues(diagnosisSessionId),
          ),
          tapResponse(
            (enteredDataValues: DiagnosisDataEntry[]) => {
              patchState(store, {
                enteredDataValues,
              });
            },
            (error: HttpErrorResponse) => {
              console.error('An error occurred in loadEnteredValues:', error);
            },
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({ getDiagnosisSessionId, loadEnteredValues, diagnosisSessionId }) {
      getDiagnosisSessionId();
      loadEnteredValues(diagnosisSessionId);
    },
  }),
);
