<div class="container w-full h-full bdr-8">
  <div class="flex-1 left relative">
    <app-challenge-list
      [challengeMasterDatas]="store.initialChallenges() || [] | sortAlphabet: 'Challenges'"
      [challengeName]="challengeName.Challenge"
      [challengeTitle]="challengeType.Challenge"
      (selectedChallengeChange)="handleChallengeSelection($event, challengeType.Challenge)"
    ></app-challenge-list>
  </div>
  <div class="flex-1 right relative flex flex-column bg-w-25">
    <app-challenge-list
      class="flex-auto"
      [subChallenges]="store.selectedSubChallenges() | sortAlphabet: 'Sub-Challenges'"
      [challengeName]="challengeName.SubChallenge"
      [challengeTitle]="challengeType.SubChallenge"
      (selectedSubChallengeChange)="handleChallengeSelection($event, challengeName.SubChallenge)"
    ></app-challenge-list>
    <div class="add-btn">
      <p-button
        class="add-btn_label"
        label="Add To Selected Challenges"
        (onClick)="addPairChallenge()"
        [disabled]="!selectedChallenge()?.name || !selectedSubChallenge()?.name"
        [ngClass]="{ 'not-disable-btn': selectedChallenge()?.name && selectedSubChallenge()?.name }"
      />
    </div>
  </div>
</div>
