<div class="view-summary-container">
  <div class="bg-w-25 bdr-8 input-infos">
    <app-review-selected-challanges></app-review-selected-challanges>
    <app-review-input-informations></app-review-input-informations>
  </div>
  <div class="bg-w-25 bdr-8 review-selected-solutions">
    <app-review-selected-solutions />
  </div>
  <div class="bg-w-25 bdr-8 diagnosis-summary-view">
    <app-diagnosis-summary-view />
  </div>
</div>
