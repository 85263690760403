import { NgClass } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';

@Component({
  selector: 'app-selected-pair-challenges',
  standalone: true,
  imports: [ButtonModule, NgClass],
  templateUrl: './selected-pair-challenges.component.html',
  styleUrl: './selected-pair-challenges.component.scss',
})
export class SelectedPairChallengesComponent implements OnInit {
  readonly #storeService = inject(StoreService);
  pairChallenges = signal<PairChallenge[]>([]);

  ngOnInit(): void {
    this.getPairChallenge();
  }

  getPairChallenge(): void {
    this.#storeService.getPairChallenge().subscribe((pairChallenge) => {
      this.pairChallenges.set(pairChallenge);
    });
  }

  handleDisable(): boolean {
    return !!this.pairChallenges().length;
  }

  removePairChallenge(challengeName: string): void {
    this.pairChallenges.update((challenges) =>
      challenges.filter(
        (challenge) => challenge.subChallengeName !== challengeName,
      ),
    );
    this.#storeService.dispatchPairChallenge(this.pairChallenges());
  }

  removeAllPairs(): void {
    this.pairChallenges.set([]);
    this.#storeService.dispatchPairChallenge([]);
  }
}
