import { createSelector } from '@ngrx/store';
import { RootState } from '../reducers/RootState.model';
import { InputState } from '../reducers/select-solution.reducer';

export const selectSolution = (state: RootState) => state.selectSolution;

export const selectInputFields = createSelector(
  selectSolution,
  (state: InputState) => {
    return {
      isAllFieldSelected: state?.isAllFieldSelected,
    };
  },
);

export const selectAvailableSolutionSelectedFlag = createSelector(
  selectSolution,
  (state: InputState) => {
    return {
      isAvailableSolutionSelected: state?.isAvailableSolutionSelected,
    };
  },
);

export const selectIsSelectSolutionFlag = createSelector(
  selectSolution,
  (state: InputState) => {
    return {
      isIsSelectSolutionFlag: state?.isIsSelectSolutionFlag,
    };
  },
);
