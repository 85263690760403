import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap } from 'rxjs';
import {
  GateAction,
  Reference,
} from 'src/app/core/common/models/master-data/metadata';
import { DiagnosisRequiredAction } from 'src/app/core/common/models/workflow/diagnosis-metadata';
import { DiagnosisSolutionCompatibility } from 'src/app/core/common/models/workflow/diagnosis-solution';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import {
  DiagnosisSummaryViewState,
  SolutionMessage,
} from './diagnosis-summary-view.model';

const initialState: DiagnosisSummaryViewState = {
  diagnosisSessionId: '',
  solutionMessages: [],
  suggestedRequiredActions: [],
  diagnosisRefrences: [],
  diagnosisRequiredAction: [],
};

export type DiagnosisSummaryViewStoreType = InstanceType<
  typeof DiagnosisSummaryViewStore
>;

export const DiagnosisSummaryViewStore = signalStore(
  withState(initialState),
  withMethods(
    (
      store,
      diagnosisSolutionService = inject(DiagnosisSolutionService),
      diagnosisSessionService = inject(DiagnosisSessionService),
      storeService = inject(StoreService),
    ) => ({
      getDiagnosisSessionId: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getSessionId()),
          tapResponse(
            (diagnosisSessionId: string) => {
              patchState(store, {
                diagnosisSessionId,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getDiagnosisSessionId:',
                error,
              );
            },
          ),
        ),
      ),
      loadSelectedSolutions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSolutionService.getSelectedSolutions(diagnosisSessionId),
          ),
          tapResponse(
            (res: DiagnosisSolutionCompatibility[]) => {
              const solutionMessages = [
                ...new Set(
                  res
                    .map((x) => x.message?.trim())
                    .filter((message) => message !== ''),
                ),
              ];
              let messages: SolutionMessage[] = [];
              solutionMessages.map((solutionMessage) => {
                solutionMessage.split('<>').forEach((msg) => {
                  const messageParts = msg.split('||');
                  const cate = messageParts[0];
                  const subParts = messageParts[1].split('>>');
                  const relationship = subParts[0];
                  const message = subParts[1];

                  messages.push({
                    cate,
                    relationship: relationship.replace('->', '⇄'),
                    message,
                  });
                });
              });

              messages = messages
                .filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex((x) => x.message === value.message),
                )
                .sort((a, b) => a.cate.localeCompare(b.cate));
              patchState(store, {
                solutionMessages: messages,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadSelectedSolutions:',
                error,
              );
            },
          ),
        ),
      ),
      loadSuggestedRequiredActions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSessionService.getSuggestedRequiredActions(
              diagnosisSessionId,
            ),
          ),
          tapResponse(
            (suggestedRequiredActions: GateAction[]) => {
              patchState(store, {
                suggestedRequiredActions,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadSuggestedRequiredActions:',
                error,
              );
            },
          ),
        ),
      ),
      loadDiagnosisReferences: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSessionService.getDiagnosisReferences(diagnosisSessionId),
          ),
          tapResponse(
            (diagnosisRefrences: Reference[]) => {
              patchState(store, {
                diagnosisRefrences,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadDiagnosisReferences:',
                error,
              );
            },
          ),
        ),
      ),
      attachSuggestedRequiredActions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSessionService.attachedSuggestedRequiredActions(
              diagnosisSessionId,
            ),
          ),
          tapResponse(
            (diagnosisRequiredAction: DiagnosisRequiredAction[]) => {
              patchState(store, {
                diagnosisRequiredAction,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in attachSuggestedRequiredActions:',
                error,
              );
            },
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({
      getDiagnosisSessionId,
      loadSelectedSolutions,
      loadSuggestedRequiredActions,
      loadDiagnosisReferences,
      attachSuggestedRequiredActions,
      diagnosisSessionId,
    }) {
      getDiagnosisSessionId();
      loadSelectedSolutions(diagnosisSessionId);
      loadSuggestedRequiredActions(diagnosisSessionId);
      loadDiagnosisReferences(diagnosisSessionId);
      attachSuggestedRequiredActions(diagnosisSessionId);
    },
  }),
);
