import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import {
  CompatibilityValueTypes,
  SolutionTitle,
} from 'src/app/core/common/enums/select-solution.enum';
import {
  DiagnosisSolutionCompatibility,
  DiagnosisSolutionCreateDto,
} from 'src/app/core/common/models/workflow/diagnosis-solution';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { AvailableSolutionStore } from './available-solutions.store';

@Component({
  selector: 'app-available-solutions',
  standalone: true,
  imports: [
    ButtonModule,
    NgTemplateOutlet,
    CheckboxModule,
    FormsModule,
    NgClass,
    TooltipModule,
    NgStyle,
  ],
  providers: [AvailableSolutionStore],
  templateUrl: './available-solutions.component.html',
  styleUrl: './available-solutions.component.scss',
})
export class AvailableSolutionsComponent {
  readonly store = inject(AvailableSolutionStore);
  readonly #storeService = inject(StoreService);

  isSolutionLoading = signal(false);

  title = SolutionTitle.Title;
  availableSolutions: DiagnosisSolutionCompatibility[] = [];
  compatibilityValueTypes = CompatibilityValueTypes;

  unselectAllSolutions(): void {
    this.store.unselectAllSolutions();
    this.refreshSolutionSelectionOnUI();
  }

  selectOneSolution(solutionId: string): void {
    this.isSolutionLoading.set(true);
    const selectedSolution: DiagnosisSolutionCreateDto = {
      diagnosisId: this.store.diagnosisSessionId(),
      solutionId,
    };
    this.store.selectOneSolution(selectedSolution);
  }

  unselectOneSolution(solutionId: string): void {
    this.isSolutionLoading.set(true);
    const selectedSolution: DiagnosisSolutionCreateDto = {
      diagnosisId: this.store.diagnosisSessionId(),
      solutionId,
    };
    this.store.unselectOneSolution(selectedSolution);
    this.store.isSolutionSelected(this.store.filteredSolutionsByCategory());
  }

  refreshSolutionSelectionOnUI(): void {
    this.store
      .filteredSolutionsByCategory()
      .forEach((item) =>
        item.compatibilities.forEach(
          (compatibility) => (compatibility.isChecked = false),
        ),
      );
    this.#storeService.dispatchSelectedSolution(false);
  }
  onCheckboxChange(compatibility: DiagnosisSolutionCompatibility): void {
    if (compatibility.isChecked)
      this.unselectOneSolution(compatibility.availableSolution.id);
    else this.selectOneSolution(compatibility.availableSolution.id);
  }

  isNotCompatible(compatibility: DiagnosisSolutionCompatibility): boolean {
    if (compatibility.compatibilityValue)
      return (
        compatibility.compatibilityValue.name.toLowerCase() ===
        CompatibilityValueTypes.NotCompatible
      );
    return false;
  }

  getSolutionIcon(compatibility: DiagnosisSolutionCompatibility): string {
    if (compatibility.compatibilityValue) {
      const blinkIcon = compatibility.message ? 'blink_me' : '';

      switch (compatibility.compatibilityValue.name.toLocaleLowerCase()) {
        case CompatibilityValueTypes.AddsSynergy:
          return `fa-solid fa-circle-check checked-icon ${blinkIcon}`;
        case CompatibilityValueTypes.NotIdeal:
          return `fa-solid fa-triangle-exclamation warning-icon ${blinkIcon}`;
        case CompatibilityValueTypes.Compatible:
          return `fa fa-info-circle info-icon ${blinkIcon}`;
      }
    }
    return '';
  }

  formatSolutionMessages(message: string): string {
    if (message !== '') {
      const compatibilityMessages = message.split('<>');
      const compatibilityMessage = compatibilityMessages.map((element) => {
        return this.formatOneMessage(element);
      });
      return compatibilityMessage.join('\n');
    }

    return '';
  }

  formatOneMessage(message: string) {
    if (message.trim() === '') return '';

    const messageParts = message.split('||');
    const subParts = messageParts[1].split('>>');
    const relationship = subParts[0];
    const content = subParts[1];

    return `${relationship.replace('->', '⇄')}: ${content}`;
  }
}
