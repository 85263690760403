import { Injectable } from '@angular/core';
import {
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MsalServiceInitializer {
  private msalInstance: IPublicClientApplication;

  constructor() {
    this.msalInstance = new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: environment.redirectUri || '/',
        postLogoutRedirectUri: '/',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        allowNativeBroker: false,
        loggerOptions: {
          loggerCallback: () => {},
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  async initialize(): Promise<void> {
    await this.msalInstance.initialize();
  }

  public getInstance(): IPublicClientApplication {
    return this.msalInstance;
  }
}
