import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { SelectedSolutions } from 'src/app/views/select-solutions/components/select-solutions-container/available-solutions/available-solutions.model';
import { DiagnosisSolutionCompatibility } from '../../common/models/workflow/diagnosis-solution';
import {
  setAvailableSolutions,
  setInputFields,
  setIsAvailableSolutionSelected,
  setIsSelectSolutionFlag,
  setSelectedSolutions,
} from '../actions/select-solution.action';

export interface InputState {
  isAllFieldSelected: boolean;
  isSelectedSolutions: boolean;
  isIsSelectSolutionFlag: boolean;
  selectedSolutions: SelectedSolutions[];
  availableSolutions: DiagnosisSolutionCompatibility[];
}

const initState: InputState = {
  isAllFieldSelected: false,
  isSelectedSolutions: false,
  isIsSelectSolutionFlag: false,
  selectedSolutions: [],
  availableSolutions: [],
};

const reducer = createReducer(
  initState,
  on(setInputFields, (state, { isAllFieldSelected }) =>
    produce(state, (draft) => {
      draft.isAllFieldSelected = isAllFieldSelected;
    }),
  ),
  on(setIsAvailableSolutionSelected, (state, { isSelectedSolutions }) =>
    produce(state, (draft) => {
      draft.isSelectedSolutions = isSelectedSolutions;
    }),
  ),
  on(setIsSelectSolutionFlag, (state, { isIsSelectSolutionFlag }) =>
    produce(state, (draft) => {
      draft.isIsSelectSolutionFlag = isIsSelectSolutionFlag;
    }),
  ),
  on(setSelectedSolutions, (state, { selectedSolutions }) =>
    produce(state, (draft) => {
      draft.selectedSolutions = selectedSolutions;
    }),
  ),
  on(setAvailableSolutions, (state, { availableSolutions }) =>
    produce(state, (draft) => {
      draft.availableSolutions = availableSolutions;
    }),
  ),
);

export const selectSolutionReducer = (
  state: InputState = initState,
  action: any,
) => reducer(state, action);
