import { Pipe, PipeTransform } from '@angular/core';
import { decodeString } from '../models/master-data/reference';

@Pipe({
  name: 'urlTransform',
  standalone: true,
})
export class UrlTransformPipe implements PipeTransform {
  transform(value: string): string {
    return decodeString(value);
  }
}
