import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { IArrayResponse } from '../common/models/general.model';
import { DiagnosisChallenge } from '../common/models/master-data/challenge';
import { GateAction, Reference } from '../common/models/master-data/metadata';
import {
  DiagnosisRequiredAction,
  DiagnosisRequiredActionUpdateDto,
} from '../common/models/workflow/diagnosis-metadata';
import { DiagnosisSession } from '../common/models/workflow/diagnosis-session';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisSessionService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  initializeSession(externalId: string, subChallenges: string[]) {
    return this.http.post<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.initialize.replace(
          ':externalId',
          externalId,
        ),
      subChallenges,
    );
  }

  restartSession(sessionId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.restart.replace(':sessionId', sessionId),
    );
  }

  setSessionInprogress(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.inprogress.replace(':sessionId', sessionId),
    );
  }

  completeSession(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.complete.replace(':sessionId', sessionId),
    );
  }

  approveSession(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.approve.replace(':sessionId', sessionId),
    );
  }

  back(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.back.replace(':sessionId', sessionId),
    );
  }

  getById(sessionId: string) {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.getById.replace(':sessionId', sessionId),
    );
  }

  getByExternalId(externalId: string): Observable<DiagnosisSession> {
    return this.http.get<DiagnosisSession>(
      this.baseUrl +
        apiConfig.diagnosisSession.getByExternalId.replace(
          ':externalId',
          externalId,
        ),
    );
  }

  getByCurrentUser() {
    return this.http.get<DiagnosisSession>(
      this.baseUrl + apiConfig.diagnosisSession.getByCurrentUser,
    );
  }

  getSessionChallenges(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisChallenge>>(
      this.baseUrl +
        apiConfig.diagnosisSession.getSessionChallenges.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getSuggestedRequiredActions(sessionId: string) {
    return this.http.get<IArrayResponse<GateAction>>(
      this.baseUrl +
        apiConfig.diagnosisMetadata.getSuggestedRequiredActions.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  attachedSuggestedRequiredActions(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisRequiredAction>>(
      this.baseUrl +
        apiConfig.diagnosisMetadata.attachedSuggestedRequiredActions.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  confirmRequiredAction(requiredAction: DiagnosisRequiredActionUpdateDto) {
    return this.http.post<DiagnosisRequiredAction>(
      this.baseUrl + apiConfig.diagnosisMetadata.confirmRequiredAction,
      requiredAction,
    );
  }

  getAttachedRequiredActions(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisRequiredAction>>(
      this.baseUrl +
        apiConfig.diagnosisMetadata.getAttachedRequiredActions.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getDiagnosisReferences(sessionId: string) {
    return this.http.get<IArrayResponse<Reference>>(
      this.baseUrl +
        apiConfig.diagnosisMetadata.getDiagnosisReferences.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }
}
