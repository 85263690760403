import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { IArrayResponse } from '../common/models/general.model';
import {
  DiagnosisSolutionCompatibility,
  DiagnosisSolutionCreateDto,
  DiagnosisSolutionGetDto,
} from '../common/models/workflow/diagnosis-solution';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisSolutionService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  selectSolution(solution: DiagnosisSolutionCreateDto) {
    return this.http.post<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl + apiConfig.diagnosisSolution.selectSolution,
      solution,
    );
  }

  unselectSolution(solution: DiagnosisSolutionGetDto) {
    return this.http.post<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl + apiConfig.diagnosisSolution.unselectSolution,
      solution,
    );
  }

  resetSolutionSelection(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl +
        apiConfig.diagnosisSolution.resetSolutionSelection.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getAvailableSolutions(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl +
        apiConfig.diagnosisSolution.getAvailableSolutions.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getSelectedSolutions(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl +
        apiConfig.diagnosisSolution.getSelectedSolutions.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getCombinedCompatibilities(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl +
        apiConfig.diagnosisSolution.getCombinedCompatibilities.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }
}
