import { createSelector } from '@ngrx/store';
import { RootState } from '../reducers/RootState.model';
import { InputState } from '../reducers/select-solution.reducer';

export const selectSolution = (state: RootState) => state.selectSolution;

export const selectInputFields = createSelector(
  selectSolution,
  (state: InputState) => {
    return {
      isAllFieldSelected: state?.isAllFieldSelected,
    };
  },
);

export const selectIsAvailableSolutionSelected = createSelector(
  selectSolution,
  (state: InputState) => {
    return state?.isSelectedSolutions;
  },
);

export const getSelectedSolution = createSelector(
  selectSolution,
  (state: InputState) => state?.selectedSolutions,
);

export const getAvailableSolutions = createSelector(
  selectSolution,
  (state: InputState) => state?.availableSolutions,
);
