import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { ArrayResult } from '../common/models/api-response';
import { DataEntryCategory } from '../common/models/master-data/data-enty';
import {
  DiagnosisDataEntry,
  DiagnosisDataEntryCreateDto,
  DiagnosisDataEntryGetDto,
} from '../common/models/workflow/diagnosis-entry';
import { DiagnosisSolutionCompatibility } from '../common/models/workflow/diagnosis-solution';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisDataEntryService {
  private http = inject(HttpClient);
  private baseUrl = environment.api;

  upsertEntryValue(
    entryValue: DiagnosisDataEntryCreateDto,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .post<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisDataEntry.upsertEntryValue, entryValue)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  removeEntryValue(
    entryValue: DiagnosisDataEntryGetDto,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .post<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisDataEntry.removeEntryValue, entryValue)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  resetEntryValue(
    sessionId: string,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisDataEntry.resetEntryValue.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getEnteredValues(sessionId: string): Observable<DiagnosisDataEntry[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisDataEntry>
      >(this.baseUrl + apiConfig.diagnosisDataEntry.getEnteredValues.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getSuggestedDataEntryCategories(
    sessionId: string,
  ): Observable<DataEntryCategory[]> {
    return this.http
      .get<
        ArrayResult<DataEntryCategory>
      >(this.baseUrl + apiConfig.diagnosisDataEntry.getSuggestedDataEntryCategories.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }
}
