import { createAction, props } from '@ngrx/store';

export const navigateToNextStep = createAction(
  '[Router] Navigate To Next Step',
  props<{
    pageRoute: string;
    sessionId: string;
    externalId?: string;
  }>(),
);
