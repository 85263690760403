import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  Permission,
  Role,
  RolePayload,
  UserPermission,
} from 'src/app/core/common/models/authorization/role';
import { IArrayResponse } from '../../../common/models/general.model';
import { AdminFeature } from '../../models/admin.model';
import { BaseService } from '../master-data/base.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService<Role> {
  constructor() {
    super(AdminFeature.ROLE_MANAGEMENT);
  }

  addRole(item: RolePayload): Observable<Role> {
    return this.http.post<Role>(this.baseUrl + this.apis['add'], item);
  }

  updateRole(item: RolePayload): Observable<Role> {
    return this.http.post<Role>(this.baseUrl + this.apis['update'], item);
  }

  getAllPermissions() {
    return this.http
      .get<
        IArrayResponse<Permission>
      >(this.baseUrl + this.apis['getAllPermissions'])
      .pipe(map((res) => res.results));
  }

  getUserPermissions() {
    return this.http
      .get<
        IArrayResponse<UserPermission>
      >(this.baseUrl + this.apis['getAssingedPermissions'])
      .pipe(map((res) => res.results));
  }

  getAssignedRoles() {
    return this.http
      .get<IArrayResponse<Role>>(this.baseUrl + this.apis['getAssingedRoles'])
      .pipe(map((res) => res.results));
  }
}
