import { createSelector } from '@ngrx/store';
import { RootState } from '../reducers/RootState.model';
import { ChallengeState } from '../reducers/select-challenge.reducer';

export const selectedChallenge = (state: RootState) => state.challenges;

export const selectPairChallenge = createSelector(
  selectedChallenge,
  (state: ChallengeState) => {
    return state?.pairChallenges;
  },
);

export const selectScreenState = createSelector(
  selectedChallenge,
  (state: ChallengeState) => {
    return state?.isInitializeDiagnosisScreen;
  },
);

export const selectExernalId = createSelector(
  selectedChallenge,
  (state: ChallengeState) => {
    return state?.diagnosisExternalId;
  },
);

export const selectSessionId = createSelector(
  selectedChallenge,
  (state: ChallengeState) => {
    return state?.diagnosisSessionId;
  },
);
