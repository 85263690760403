import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import {
  AvailableSolutionBlockTitle,
  SolutionTitle,
} from 'src/app/core/common/constants/select-solution.enum';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { CheckboxModule } from 'primeng/checkbox';
import { setAvailableSolutionSelectedFlag } from 'src/app/core/store/actions/select-solution.action';
import { selectInputFields } from 'src/app/core/store/selectors/select-solution.selectors';

@Component({
  selector: 'app-available-solutions',
  standalone: true,
  imports: [
    ButtonModule,
    NgTemplateOutlet,
    CheckboxModule,
    FormsModule,
    NgClass,
    NgStyle,
  ],
  templateUrl: './available-solutions.component.html',
  styleUrl: './available-solutions.component.scss',
})
export class AvailableSolutionsComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);
  title = SolutionTitle.Title;
  availableSolutionTitle = AvailableSolutionBlockTitle;
  isEmptySolution = signal(true);

  // Temporary function for mock data support; will be removed after API integration.
  isDisableUnselectAllBtn = signal(true);
  solutions: any;
  initialSolutions = [
    {
      solutionName: this.availableSolutionTitle.Tools,
      solutionItem: [
        { isChecked: false, name: 'Wash Nozzle' },
        { isChecked: false, name: 'Coilsweep' },
        { isChecked: false, name: 'Pulsonix', tagName: 'compatible' },
        { isChecked: false, name: 'Milling Tool', tagName: 'Synergy' },
        { isChecked: false, name: 'Venturi Tool' },
      ],
    },
    {
      solutionName: this.availableSolutionTitle.Chemicals,
      solutionItem: [
        { isChecked: false, name: 'Aqualinear', tagName: 'compatible' },
        { isChecked: false, name: 'Biovert', tagName: 'not ideal' },
        { isChecked: false, name: 'Logard', tagName: 'compatible' },
        { isChecked: false, name: 'N2', tagName: 'not ideal' },
        { isChecked: false, name: 'N2 Foam', tagName: 'not ideal' },
      ],
    },
    {
      solutionName: this.availableSolutionTitle.Conveyance,
      solutionItem: [
        { isChecked: false, name: 'CT', tagName: 'Synergy' },
        { isChecked: false, name: 'HWO', isDisabled: true, tagName: '' },
        { isChecked: false, name: 'Spectrum', tagName: 'Synergy' },
      ],
    },
  ];

  ngOnInit(): void {
    this.getAllInputSelected();
    this.solutions = cloneDeep(this.initialSolutions);
    this.#globalStore.dispatch(
      setAvailableSolutionSelectedFlag({
        isAvailableSolutionSelected: false,
      }),
    );
  }

  getAllInputSelected(): void {
    this.#globalStore
      .select(selectInputFields)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isEmptySolution.set(!res.isAllFieldSelected);
      });
  }

  // Temporary function for mock data support; will be removed after API integration.
  unselectAllSolutions(): void {
    this.solutions = cloneDeep(this.initialSolutions);
    this.dispatchSelectSolutionFlag();
    this.solutions.map((v: any) => {
      v.solutionItem.forEach((i: any) => (i.isChecked = false));
    });
    document
      .querySelectorAll<HTMLInputElement>('.circle-overlay-front')
      .forEach((item) => (item.checked = false));
  }

  // Temporary function for mock data support; will be removed after API integration.
  onCheckboxChange(item: any): void {
    this.solutions = this.solutions.map((v: any) => ({
      ...v,
      solutionItem: v.solutionItem.map((i: any) =>
        i.name === item.name ? { ...i, isChecked: !item.isChecked } : i,
      ),
    }));
    this.dispatchSelectSolutionFlag();
  }

  // Temporary function for mock data support; will be removed after API integration.
  dispatchSelectSolutionFlag(): void {
    const isAvailableSolutionSelected = this.solutions.some((v: any) =>
      v.solutionItem.some((i: any) => i.isChecked),
    );

    this.#globalStore.dispatch(
      setAvailableSolutionSelectedFlag({ isAvailableSolutionSelected }),
    );
    this.isDisableUnselectAllBtn.set(!isAvailableSolutionSelected);
  }
}
