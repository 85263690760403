import { Component, inject, input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { REQUEST_PARAM } from 'src/app/core/common/constants/routes.constant';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { ChallengesSubChallengesComponent } from './challenges-sub-challenges/challenges-sub-challenges.component';
import { SelectedPairChallengesComponent } from './selected-pair-challenges/selected-pair-challenges.component';

@Component({
  selector: 'app-select-challenges-container',
  standalone: true,
  imports: [ChallengesSubChallengesComponent, SelectedPairChallengesComponent],
  templateUrl: './select-challenges-container.component.html',
  styleUrl: './select-challenges-container.component.scss',
})
export class SelectChallengesContainerComponent implements OnInit {
  readonly #activeRoute = inject(ActivatedRoute);
  readonly #storeService = inject(StoreService);
  sid = input<string>();
  eid = input<string>();

  ngOnInit(): void {
    this.#activeRoute.paramMap.subscribe((params) => {
      const externalId = params.get(REQUEST_PARAM.ExternalId) ?? '';
      const sessionId = params.get(REQUEST_PARAM.SessionId) ?? '';
      this.#storeService.dispatchExternalId(externalId);
      this.#storeService.dispatchSessionId(sessionId);
    });
  }
}
