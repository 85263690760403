import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap } from 'rxjs';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { DiagnosisSolutionCompatibility } from '../../models/workflow/diagnosis-solution';
import { ReviewSelectedSolutionsState } from './review-selected-solutions.model';

const initialState: ReviewSelectedSolutionsState = {
  diagnosisSessionId: '',
  selectedSolutions: [],
};

export type ReviewSelectedSolutionsStoreType = InstanceType<
  typeof ReviewSelectedSolutionsStore
>;

export const ReviewSelectedSolutionsStore = signalStore(
  withState(initialState),
  withMethods(
    (
      store,
      diagnosisSolutionService = inject(DiagnosisSolutionService),
      storeService = inject(StoreService),
    ) => ({
      getDiagnosisSessionId: rxMethod<void>(
        pipe(
          switchMap(() => storeService.getSessionId()),
          tapResponse(
            (diagnosisSessionId: string) => {
              patchState(store, {
                diagnosisSessionId,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in getDiagnosisSessionId:',
                error,
              );
            },
          ),
        ),
      ),
      loadSelectedSolutions: rxMethod<string>(
        pipe(
          filter((diagnosisSessionId) => !!diagnosisSessionId),
          switchMap((diagnosisSessionId: string) =>
            diagnosisSolutionService.getSelectedSolutions(diagnosisSessionId),
          ),
          tapResponse(
            (selectedSolutions: DiagnosisSolutionCompatibility[]) => {
              patchState(store, {
                selectedSolutions,
              });
            },
            (error: HttpErrorResponse) => {
              console.error(
                'An error occurred in loadSelectedSolutions:',
                error,
              );
            },
          ),
        ),
      ),
    }),
  ),
  withHooks({
    onInit({
      getDiagnosisSessionId,
      loadSelectedSolutions,
      diagnosisSessionId,
    }) {
      getDiagnosisSessionId();
      loadSelectedSolutions(diagnosisSessionId);
    },
  }),
);
