import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ReviewInputInformationsComponent } from 'src/app/core/common/components/review-input-informations/review-input-informations.component';
import { ReviewSelectedChallangesComponent } from 'src/app/core/common/components/review-selected-challanges/review-selected-challanges.component';
import { ReviewSelectedSolutionsComponent } from 'src/app/core/common/components/review-selected-solutions/review-selected-solutions.component';
import { REQUEST_PARAM } from 'src/app/core/common/constants/routes.constant';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { RequiredActionComponent } from './required-action/required-action.component';

@Component({
  selector: 'app-required-actions-container',
  standalone: true,
  imports: [
    ReviewSelectedChallangesComponent,
    ReviewInputInformationsComponent,
    ReviewSelectedSolutionsComponent,
    RequiredActionComponent,
  ],
  templateUrl: './required-actions-container.component.html',
  styleUrl: './required-actions-container.component.scss',
})
export class RequiredActionsContainerComponent implements OnInit {
  readonly #activeRoute =
    inject(ActivatedRoute).paramMap.pipe(takeUntilDestroyed());
  readonly #storeService = inject(StoreService);

  ngOnInit(): void {
    this.#activeRoute.subscribe((params) => {
      const sessionId = params.get(REQUEST_PARAM.SessionId);
      if (sessionId) this.#storeService.dispatchSessionId(sessionId);
    });
  }
}
