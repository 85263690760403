import { inject, Injectable, Type } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { PslSelectionComponent } from 'src/app/views/select-challenge/components/select-challenges-container/psl-selection/psl-selection.component';
import { SessionHistoryComponent } from '../../../views/admin-features/session-management/session-history/session-history.component';
import { UomSettingComponent } from '../../../views/select-challenge/components/select-challenges-container/uom-setting/uom-setting.component';
import { ConfirmDialogComponent } from '../../common/components/modals/confirm-dialog/confirm-dialog.component';
import { SaveDialogComponent } from '../../common/components/modals/save-dialog/save-dialog.component';
import { WarningDialogComponent } from '../../common/components/modals/warning-dialog/warning-dialog.component';
import {
  ConfirmDialogInfo,
  SaveDialogInfo,
  WarningDialogInfo,
} from '../../common/models/dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogCommonService {
  #dialogService = inject(DialogService);
  #dialogRef: DynamicDialogRef | undefined;

  confirm(data: ConfirmDialogInfo) {
    this.#dialogRef = this.#dialogService.open(ConfirmDialogComponent, {
      modal: true,
      header: data.title,
      width: data.width || '40%',
      showHeader: true,
      closable: true,
      breakpoints: {
        '960px': '60%',
        '640px': '60%',
      },
      styleClass: 'confirm-dialog overlow-hidden',
      data,
    });

    return this.#dialogRef.onClose;
  }

  show(modal: boolean, config: WarningDialogInfo) {
    this.#dialogRef = this.#dialogService.open(WarningDialogComponent, {
      modal,
      header: config.title,
      width: config.width || '30%',
      showHeader: true,
      closable: config.closable,
      styleClass: 'warning-dialog overflow-hidden',
      data: config,
      focusOnShow: false,
    });

    return this.#dialogRef.onClose;
  }

  save(config: SaveDialogInfo) {
    this.#dialogRef = this.#dialogService.open(SaveDialogComponent, {
      modal: true,
      header: config.title,
      width: '40%',
      showHeader: true,
      closable: true,
      breakpoints: {
        '960px': '50%',
        '640px': '50%',
      },
      styleClass: 'save-dialog overlow-hidden',
      data: config,
    });

    return this.#dialogRef.onClose;
  }

  openPSLSelection() {
    this.#dialogRef = this.#dialogService.open(PslSelectionComponent, {
      modal: true,
      width: '30%',
      header: 'PSL Selection',
      showHeader: true,
      closable: true,
      breakpoints: {
        '960px': '50%',
        '640px': '50%',
      },
      focusOnShow: false,
    });

    return this.#dialogRef.onClose;
  }

  openUserSetting() {
    this.#dialogRef = this.#dialogService.open(UomSettingComponent, {
      modal: true,
      width: '30%',
      header: 'UoM Settings',
      showHeader: true,
      closable: true,
      breakpoints: {
        '1024px': '50%',
        '960px': '80%',
        '640px': '90%',
      },
      focusOnShow: false,
    });

    return this.#dialogRef.onClose;
  }

  openHistoryDialog(sessionId: string) {
    this.#dialogRef = this.#dialogService.open(SessionHistoryComponent, {
      modal: true,
      width: '60%',
      header: 'History',
      showHeader: true,
      closable: true,
      styleClass: 'session-history-dialog overflow-hidden',
      data: {
        sessionId,
      },
      focusOnShow: false,
    });
  }

  openDialog(dialogComponent: Type<unknown>, config: DynamicDialogConfig) {
    return this.#dialogService.open(dialogComponent, config);
  }
}
