import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ChallengesResponse } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectChallengeService {
  #http = inject(HttpClient);
  private apiUrl = environment.api;

  getActiveChallenges(): Observable<ChallengesResponse> {
    return this.#http
      .get<ChallengesResponse>(`${this.apiUrl}/Challenge/active`)
      .pipe(
        map((res: ChallengesResponse) => {
          return res;
        }),
      );
  }

  getActiveSubChallenges(): Observable<any> {
    return this.#http.get<any>(`${this.apiUrl}/SubChallenge/active`).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getSubChallengesById(challengeId: string): Observable<any> {
    return this.#http
      .get<any>(`${this.apiUrl}/SubChallenge/${challengeId}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  }
}
