<div class="action">
  <div class="action-header">
    <h2 class="action-header-header">Required Actions</h2>
  </div>

  @if(!isDesignApproved()) {
  <div class="action-info">
    <i class="fa-solid fa-circle-info action-info_icon inline-block font-size-10"></i>
    <span class="action-info_message inline-block font-size-10"
      >Please check each box to acknowledge that the required action has been taken, or the special consideration has been taken into account.</span
    >
  </div>
  } @for (section of actionsByCate; track $index) {
  <div class="action-checkbox">
    <div class="action-checkbox_title">{{ section.category }}</div>
    <form class="action-checkbox_form" [formGroup]="formGroup">
      @for (item of section.gateActions; track $index) {
      <div
        class="action-checkbox_form-item"
        [ngClass]="{
        'border-bottom': $index < section.gateActions.length - 1 && isDesignApproved(),
        'design-approved': isDesignApproved()
      }"
      >
        @if(!isDesignApproved() && diagnosisSession?.diagnosisStatus?.name !== status.APPROVED_FOR_DESIGN) {
        <input
          type="checkbox"
          [id]="item.gateAction.name"
          class="checkbox center-flex"
          (change)="onCheckboxChange(item)"
          [checked]="item.isCompleted"
        />
        }
        <label [for]="item.gateAction.name" class="name">{{ item.gateAction.name }} ({{ item.relatedSolutions }})</label>
      </div>
      }
    </form>
  </div>
  }
</div>
