<div class="container">
  <div class="solution">
    <div class="solution-title inline-block">
      <h2 class="solution_title">{{ title }}</h2>
    </div>

    <!-- Temporary function for mock data support; will be removed after API integration. -->
    @if (isEmptySolution()) {
    <div class="solution-empty">
      <h3 class="no-data">No Data yet</h3>
      <span>Once required inputs is filled, you will see data here</span>
    </div>
    } @else {
    <div class="solution-unselect-btn inline-block">
      <p-button
        class="unselect-btn"
        label="Unselect All"
        (onClick)="unselectAllSolutions()"
        [disabled]="isDisableUnselectAllBtn()"
        [ngClass]="{'unselect-btn-disabled': isDisableUnselectAllBtn()}"
      />
    </div>

    <ng-container>
      <ng-container *ngTemplateOutlet="availableSolutions; context: { solution: solutions }"></ng-container>
    </ng-container>
    }
  </div>
</div>

<ng-template #availableSolutions let-solution="solution">
  <div class="solution-wrapper">
    @for(solution of solutions; track solution.solutionName) {
    <div class="solution-block">
      <div class="solution-header">
        <i class="fa-solid fa-screwdriver-wrench solution-block_icon inline-block"></i>
        <h3 class="solution-block_header inline-block">{{ solution.solutionName }}</h3>
      </div>
      @for (item of solution.solutionItem; track item.name) {
      <div class="solution-item" [ngClass]="{ 'selected-solution': item.isChecked }">
        <div class="image-container" [ngClass]="{'solution-disabled': item.isDisabled}">
          <img src="assets/halliburton_select_solution.jpg" alt="solution-img" class="solution-item_img" />
          @if (!item.isDisabled) {
          <div class="circle-overlay center-flex">
            <input type="checkbox" id="checkbox" class="circle-overlay-front center-flex" (change)="onCheckboxChange(item)" />
          </div>
          }
        </div>
        <h3 class="solution-item_name">{{ item.name }}</h3>
        @if (item.tagName) {
        <div
          class="solution-item_tag"
          [ngClass]="item.tagName === 'Synergy' ? 'checked-tag' : item.tagName === 'not ideal' ? 'warning-tag' : item.tagName === 'compatible' ? 'info-tag' : ''"
        >
          <i
            [ngClass]="item.tagName === 'Synergy' ? 'fa-solid fa-circle-check checked-icon' : 
          item.tagName === 'not ideal' ? 'fa-solid fa-triangle-exclamation warning-icon' : 
          item.tagName === 'compatible' ? 'fa fa-info-circle info-icon' : ''"
          ></i>
          <h3 class="tag-name">{{ item.tagName }}</h3>
        </div>
        }
      </div>
      }
      <!-- End of Temporary function for mock data support; will be removed after API integration. -->
    </div>
    }
  </div>
</ng-template>
