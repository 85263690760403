import { Component, inject } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SolutionTitle } from 'src/app/core/common/enums/select-solution.enum';
import { DiagnosisDataEntry } from '../../models/workflow/diagnosis-entry';
import { ReviewInputInformationsStore } from './review-input-informations.store';

@Component({
  selector: 'app-review-input-informations',
  standalone: true,
  imports: [ProgressSpinnerModule],
  providers: [ReviewInputInformationsStore],
  templateUrl: './review-input-informations.component.html',
  styleUrl: './review-input-informations.component.scss',
})
export class ReviewInputInformationsComponent {
  readonly store = inject(ReviewInputInformationsStore);
  title = SolutionTitle;

  getEnteredValue(value: DiagnosisDataEntry) {
    return value.uoM
      ? `${value.inputValue} ${value.uoM.name}`
      : value.dataEntryValueName;
  }
}
