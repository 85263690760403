<div class="container">
  <div class="wrapper-image">
    <img src="assets/illus.svg" alt="illus" class="illus" />
    <img src="assets/stepper.png" alt="stepper" class="stepper" />
  </div>
  <div class="stepper-horizontal" id="stepper1">
    <ng-container>
      <ng-template *ngTemplateOutlet="stepMapping; context: { stepMasters: stepMasters }"></ng-template>
    </ng-container>
  </div>
</div>

<ng-template #stepMapping let-stepMasters="stepMasters">
  @for(step of stepMasters; track step.id) {
  <div class="step inline-div" [ngClass]="{'editing': step.stepNumber === currentStep}">
    <div class="step-number" [ngClass]="{'next-step': step.isChecked}">{{ step.stepNumber }}</div>
    <div class="step-title">{{ step.title }}</div>
    <div class="step-bar-right"></div>
  </div>
  }
</ng-template>
