import { Routes } from '@angular/router';
import { RequiredActionsContainerComponent } from './views/required-actions/components/required-actions-container/required-actions-container.component';
import { SelectChallengesContainerComponent } from './views/select-challenge/components/select-challenges-container/select-challenges-container.component';
import { SelectSolutionsContainerComponent } from './views/select-solutions/components/select-solutions-container/select-solutions-container.component';
import { DiagnosisSummaryViewContainerComponent } from './views/view-summary/components/diagnosis-summary-view-container/diagnosis-summary-view-container.component';

export const routes: Routes = [
  { path: '', redirectTo: '/select-challenges', pathMatch: 'full' },
  {
    path: ':sid',
    component: SelectChallengesContainerComponent,
  },
  {
    path: 'eid/:eid',
    component: SelectChallengesContainerComponent,
  },
  {
    path: 'sid/:sid',
    component: SelectChallengesContainerComponent,
  },
  {
    path: 'select-challenges',
    component: SelectChallengesContainerComponent,
  },
  {
    path: 'select-solutions/:sid',
    component: SelectSolutionsContainerComponent,
  },
  {
    path: 'view-summary/:sid',
    component: DiagnosisSummaryViewContainerComponent,
  },
  {
    path: 'required-actions/:sid',
    component: RequiredActionsContainerComponent,
  },
];
