import { IAdminState } from '../../../admin-features/models/admin.model';
import { IGeneralItem } from '../general.model';
import { IReferenceCategory } from './reference-category';
import { Solution } from './solution';

export interface IReference extends IGeneralItem {
  name: string;
  hmsNumber: string;
  description: string;
  documentLink: string;
  referenceCategoryId: string;
  referenceCategoryName: string;
  isValid: boolean;
  solutionReferences?: ISolutionReference[];
}

export interface ISolutionReference {
  referenceId: string;
  solutionId: string;
  solutionName: string;
  solutionDescription: string;
  description: string;
  isRelevant: boolean;
}

export interface IReferenceState extends IAdminState<IReference> {
  solutions: Solution[] | undefined;
  referenceCategories: IReferenceCategory[] | undefined;
  referenceCategoryNames: string[];
  solutionReferences: ISolutionReference[] | undefined;
}

export const encodeString = (value: string) => btoa(value);
export const decodeString = (value: string) => {
  try {
    // if value is base64 encoded, decode it
    return atob(value);
  } catch {
    //otherwise, return the value
    return value;
  }
};
