<div class="solution">
  <div class="solution-wrapper">
    <h2 class="solution_title">Selected Solutions</h2>
  </div>

  @defer (when (store.selectedSolutions() && store.selectedSolutions().length)){
  <div class="solution-info">
    @for (compatibility of store.selectedSolutions(); track $index) {
    <div class="solution-info_wrapper">
      @if ( compatibility.compatibilityValue && compatibility.compatibilityValue.hexColor) {
      <div class="solution-info_color inline-block" [ngStyle]="{ 'background-color': compatibility.compatibilityValue!.hexColor}"></div>
      }
      <div class="solution-info_name inline-block">{{ compatibility.availableSolution.name }}</div>
    </div>
    }
  </div>
  } @placeholder {
  <p-progressSpinner ariaLabel="loading" class="center-flex" />
  }
</div>
