import { requiredActionReducer } from './required-action.reducer';
import { challengesReducer } from './select-challenge.reducer';
import { selectSolutionReducer } from './select-solution.reducer';

/**
 * The key must be the same with the key in RootState
 */
export const rootReducer = {
  challenges: challengesReducer,
  selectSolution: selectSolutionReducer,
  requiredAction: requiredActionReducer,
};
