import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { PairChallenge } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.model';
import { setChallenges } from '../actions/select-challenge.actions';

export interface PairChallengeState {
  pairChallenges: PairChallenge[];
}

const initState: PairChallengeState = {
  pairChallenges: [],
};

const reducer = createReducer(
  initState,
  on(setChallenges, (state, { pairChallenges }) =>
    produce(state, (draft) => {
      draft.pairChallenges = pairChallenges;
    }),
  ),
);

export const challengesReducer = (
  state: PairChallengeState = initState,
  action: any,
) => reducer(state, action);
