import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { setChallenges } from 'src/app/core/store/actions/select-challenge.actions';
import { selectPairChallenge } from 'src/app/core/store/selectors/select-challenge.selectors';

import {
  ChallengeName,
  ChallengeType,
} from 'src/app/core/common/constants/select-challenge.enum';
import { ChallengeListComponent } from './challenge-list/challenge-list.component';
import {
  ChallengeResult,
  PairChallenge,
  SubChallenge,
} from './challenges-sub-challenges.model';
import { ChallengesSubChallengesStore } from './challenges-sub-challenges.store';

@Component({
  selector: 'app-challenges-sub-challenges',
  standalone: true,
  imports: [ButtonModule, NgClass, TableModule, ChallengeListComponent],
  providers: [ChallengesSubChallengesStore],
  templateUrl: './challenges-sub-challenges.component.html',
  styleUrl: './challenges-sub-challenges.component.scss',
})
export class ChallengesSubChallengesComponent {
  #globalStore = inject(Store);
  store = inject(ChallengesSubChallengesStore);
  #destroyRef = inject(DestroyRef);

  filteredSubChallenges = signal<SubChallenge[]>([]);
  challengeType = ChallengeType;
  challengeName = ChallengeName;
  selectedChallenge = signal<ChallengeResult>(
    this.store.initialChallengeResult(),
  );
  selectedSubChallenge = signal<SubChallenge>(this.store.initialSubChallenge());
  pairChallenges = signal<PairChallenge[]>([]);

  handleChallengeSelection(
    selectedChallenge: ChallengeResult | SubChallenge,
    challengeType: string,
  ): void {
    if (challengeType === ChallengeType.Challenge) {
      const challenge = selectedChallenge as ChallengeResult;
      this.filteredSubChallenges.set(challenge?.value?.subChallenges ?? []);
      this.selectedChallenge.set(challenge);
      this.resetToDefaultSubChallenge();
      this.getPairChallenge();
      return;
    }

    this.selectedSubChallenge.set(selectedChallenge as SubChallenge);
  }

  disableAddPairChallengeBtn(): boolean {
    return !(
      this.selectedChallenge()?.value.name && this.selectedSubChallenge()?.name
    );
  }

  addPairChallenge(): void {
    if (!this.selectedChallenge() || !this.selectedSubChallenge()) {
      return;
    }
    this.getPairChallenge();
    this.pairChallenges.update((currentChallenges = []) => [
      ...currentChallenges,
      {
        challengeName: this.selectedChallenge().value.name,
        subChallengeName: this.selectedSubChallenge().name,
      },
    ]);
    this.dispatchPairChallenge();
    this.resetToDefaultSubChallenge();
  }

  dispatchPairChallenge(): void {
    this.#globalStore.dispatch(
      setChallenges({
        pairChallenges: this.pairChallenges(),
      }),
    );
  }

  getPairChallenge(): void {
    this.#globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.pairChallenges.set(res.pairChallenges);
        this.disableUsedSubChallenges();
        this.setInInitalChallenges();
      });
  }

  disableUsedSubChallenges(): void {
    const usedSubChallenges = new Set(
      this.pairChallenges().map((item) => item.subChallengeName),
    );

    this.filteredSubChallenges.update((challenges) =>
      challenges.map((challenge: SubChallenge) => ({
        ...challenge,
        isDisabled: usedSubChallenges.has(challenge.name),
      })),
    );
  }

  resetToDefaultSubChallenge(): void {
    this.selectedSubChallenge.set(this.store.initialSubChallenge());
  }

  setInInitalChallenges(): void {
    this.store.setInInitialChallenge(this.pairChallenges());
  }
}
