import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogInfo } from '../../../models/dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [FormsModule, ButtonModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent implements OnInit {
  title = 'Confirmation';
  message = 'Are you sure to perform this action?';
  cancelLabel = 'Ok';
  confirmLabel = 'Cancel';
  type = 'danger';

  #dialogRef = inject(DynamicDialogRef);
  #dialogConfig = inject(DynamicDialogConfig);

  ngOnInit(): void {
    const config: ConfirmDialogInfo = this.#dialogConfig.data;
    if (config) {
      this.type = config.type ?? this.type;
      this.title = config.title ?? this.title;
      this.message = config.message ?? this.message;
      this.cancelLabel = config.cancelLabel ?? this.cancelLabel;
      this.confirmLabel = config.confirmLabel ?? this.confirmLabel;
    }
  }

  confirm(): void {
    this.#dialogRef.close(true);
  }

  decline(): void {
    this.#dialogRef.close(false);
  }
}
