import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ImageModule } from 'primeng/image';
import { selectIsSelectSolutionFlag } from 'src/app/core/store/selectors/select-solution.selectors';

@Component({
  selector: 'app-step-master',
  standalone: true,
  imports: [NgTemplateOutlet, NgClass, ImageModule, NgOptimizedImage],
  templateUrl: './step-master.component.html',
  styleUrl: './step-master.component.scss',
})
export class StepMasterComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);

  // Temporary function for mock data support; will be removed after API integration.
  currentStep = 1;
  numSteps = 3;
  stepMasters = [
    {
      id: 'stepper1',
      stepNumber: 1,
      title: 'Select challenges',
      isChecked: true,
    },
    {
      id: 'stepper2',
      stepNumber: 2,
      title: 'Select solutions',
      isChecked: false,
    },
    { id: 'stepper3', stepNumber: 3, title: 'View summary', isChecked: false },
  ];

  ngOnInit(): void {
    this.getValidInputForms();
  }

  getValidInputForms(): void {
    this.#globalStore
      .select(selectIsSelectSolutionFlag)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.stepMasters.forEach((step) => {
          if (step.id === 'stepper2') {
            step.isChecked = res.isIsSelectSolutionFlag;
          }
        });
      });
  }
}
