import { createAction, props } from '@ngrx/store';

export const setAllRequiredCheckboxesSelected = createAction(
  '[Solutions] Set All Required Checkboxes Selected',
  props<{ areAllRequiredCheckboxesSelected: boolean }>(),
);

export const setIsDesignApproved = createAction(
  '[Solutions] Set Is Design Approved',
  props<{ isDesignApproved: boolean }>(),
);
