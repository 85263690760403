import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { masterDataApi } from '../../../../api.config';
import { StatisticsInfo } from '../../../../views/admin-features/common/admin-dashboard/components/statistics/statistics.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  getStatisticInfo(): Observable<StatisticsInfo> {
    return this.http.get<StatisticsInfo>(
      this.baseUrl + masterDataApi.general.statistic,
    );
  }
}
