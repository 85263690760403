import { Component, effect, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AdminStore } from '../../../../core/admin-features/store/admin.store';
import { IQuickAccessItem } from '../../../admin-features/common/admin-dashboard/components/quick-access-card/quick-access-card.model';

@Component({
  selector: 'app-left-navigation',
  standalone: true,
  imports: [RouterLinkActive, RouterLink],
  templateUrl: './left-navigation.component.html',
  styleUrl: './left-navigation.component.scss',
})
export class LeftNavigationComponent {
  readonly store = inject(AdminStore);
  #router = inject(Router);
  menuItems: IQuickAccessItem[] = [];

  constructor() {
    effect(() => {
      this.menuItems = [...this.store.quickAccessItems()];
    });
  }

  toggleItemExpanded(item: IQuickAccessItem): void {
    item.expanded = !item.expanded;
  }

  onClickMenuItem(item: IQuickAccessItem) {
    if (item.children?.length) {
      if (this.store.menuExpanded()) item.expanded = !item.expanded;
      else this.#router.navigate(['/admin', item.children[0].url]);
    } else if (item.url) {
      this.#router.navigate(['/admin', item.url]);
    }
  }
}
