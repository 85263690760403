import { createSelector } from '@ngrx/store';
import { RootState } from '../reducers/RootState.model';
import { PairChallengeState } from '../reducers/select-challenge.reducer';

export const selectedChallenge = (state: RootState) => state.challenges;

export const selectPairChallenge = createSelector(
  selectedChallenge,
  (state: PairChallengeState) => {
    return {
      pairChallenges: state?.pairChallenges,
    };
  },
);
