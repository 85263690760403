<div class="container">
  <app-challenge-list
    [challengeMasterDatas]="store.initialChallenges()?.results ?? []"
    [challengeName]="challengeName.Challenge"
    [challengeTitle]="challengeType.Challenge"
    (selectedChallengeChange)="handleChallengeSelection($event, challengeType.Challenge)"
  ></app-challenge-list>
  <div class="border"></div>

  <app-challenge-list
    [subChallenges]="filteredSubChallenges()"
    [challengeName]="challengeName.SubChallenge"
    [challengeTitle]="challengeType.SubChallenge"
    (selectedSubChallengeChange)="handleChallengeSelection($event, challengeName.SubChallenge)"
  ></app-challenge-list>

  <div class="add-btn">
    <p-button
      class="add-btn_label pd-16"
      label="Add To Selected Challenges"
      (onClick)="addPairChallenge()"
      [disabled]="disableAddPairChallengeBtn()"
      [ngClass]="{'not-disable-btn': !disableAddPairChallengeBtn()}"
    />
  </div>
</div>
