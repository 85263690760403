export const apiConfig = {
  masterData: {
    getActiveChallenges: '/master/challenge/active',
    getSubChallengesByGetChallengeId: '/master/sub-challenge/:challengeId',
    getActiveSubChallenges: '/master/sub-challenge/active',
  },
  diagnosisSession: {
    initialize: '/session/initialize/:externalId',
    restart: '/session/restart/:sessionId',
    inprogress: '/session/inprogress/:sessionId',
    complete: '/session/complete/:sessionId',
    approve: '/session/approve/:sessionId',

    getById: '/session/:sessionId',
    getByExternalId: '/session/:externalId',
    getByCurrentUser: '/session/current-user',
    getSessionChallenges: '/session/:sessionId/challenges',
  },
  diagnosisDataEntry: {
    upsertEntryValue: '/session/entry-value/upsert',
    removeEntryValue: '/session/entry-value/remove',
    resetEntryValue: '/session/:sessionId/entry-value/reset',
    getEnteredValues: '/session/:sessionId/entry-value/entered-values',

    getSuggestedDataEntryCategories: '/session/:sessionId/entry-categories',
  },
  diagnosisSolution: {
    selectSolution: '/session/solution/add',
    unselectSolution: '/session/solution/remove',
    resetSolutionSelection: '/session/:sessionId/solution/reset',

    getAvailableSolutions: '/session/:sessionId/available-solutions',
    getSelectedSolutions: '/session/:sessionId/selected-solutions',
    getCombinedCompatibilities: '/session/:sessionId/solution-compatibilities',
  },
  diagnosisMetadata: {
    getSuggestedRequiredActions: '/session/:sessionId/suggested-actions',
    attachedSuggestedRequiredActions:
      '/session/:sessionId/suggested-action/attach',
    confirmRequiredAction: '/session/attached-action/update',
    getAttachedRequiredActions: '/session/:sessionId/attached-actions',
    getDiagnosisReferences: '/session/:sessionId/references',
  },
};
