<div class="container h-full flex flex-column">
  <div class="challenge">
    <div class="challenge_content-left">
      <h2 class="challenge_title">Selected Challenges</h2>
    </div>
    <div class="challenge_content-right" (click)="removeAllPairs()">
      <i class="pi pi-times challenge_clear-icon" [ngClass]="{ 'not-disable-btn': store.pairChallenges().length }"></i>
      <p-button
        label="Clear All"
        class="challenge_input"
        [disabled]="!store.pairChallenges().length"
        [ngClass]="{ 'not-disable-btn': store.pairChallenges().length }"
      ></p-button>
    </div>
  </div>

  @if (store.pairChallenges().length) {
    <div class="pairs-wrapper flex-auto flex flex-column">
      <div class="pairs-wrapper_total">Total: {{ store.pairChallenges().length }} Item(s)</div>
      <div class="pairs-wrapper_content flex-auto">
        @for (pairChallenge of store.pairChallenges(); track pairChallenge.subChallengeName) {
          <div class="pairs-content">
            <div class="pairs-content_text">
              <span>{{ pairChallenge.challengeName }} - {{ pairChallenge.subChallengeName }}</span>
            </div>
            <div class="pairs-content_icon" (click)="removePairChallenge(pairChallenge)">
              <i class="pi pi-times remove-icon"></i>
            </div>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="challenge_content-center">
      <h3 class="title">No Data yet</h3>
      <span>Once a challenge is selected, you will see data here</span>
    </div>
  }
</div>
