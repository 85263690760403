<div class="container flex flex-column gap-2">
  <div class="flex justify-content-between">
    <div class="flex">
      <a class="logo flex align-items-center">
        <img src="assets/halliburton.svg" alt="halliburton-logo" width="120" height="40" />
      </a>
      <span class="name flex align-items-center ml-2">3D WORKFLOW</span>
    </div>
    <li class="username flex align-items-center justify-content-end">
      <p-menu #menu [model]="items()" [popup]="true" styleClass="menu-user" appendTo="body" />
      <p-avatar [label]="labelAvatar" class="mr-2" size="normal" shape="circle" styleClass="avatar-user" (click)="menu.toggle($event)" />
    </li>
  </div>
  <div class="flex justify-content-between flex-wrap gap-2">
    <div class="flex align-items-center">
      @if (currentStep() === ROUTES.SELECT_SOLUTIONS || currentStep() === ROUTES.VIEW_SUMMARY) {
        <div class="mr-1 lg:mr-3">
          <p-button class="restart-btn" (onClick)="restartWorkflow()">
            <div class="restart-btn_title">New WorkFlow</div>
            <i class="fa fa-refresh restart-btn_icon" aria-hidden="true"></i>
          </p-button>
        </div>
      }
      @if (currentStep() && currentStep() !== ROUTES.SELECT_CHALLENGES) {
        <p-tag [value]="diagnosisSessionStatus" styleClass="tag-status" [class]="STATUS_CLASS_MAPPING[diagnosisSessionStatus]" />
      }
    </div>
    <div class="flex">
      @if (currentStep() === ROUTES.VIEW_SUMMARY || (currentStep() === ROUTES.REQUIRED_ACTIONS && !isHideApprovedForDesignBtn())) {
        <div class="back">
          <p-button class="back-btn" (click)="backToPreviousScreen()">
            <div class="back-btn_title">Back</div>
            <i class="fa fa-arrow-left back-btn_icon" aria-hidden="true"></i>
          </p-button>
        </div>
      }

      <div class="initialize-grid-item ml-1 lg:ml-3">
        @if (!isHideApprovedForDesignBtn()) {
          <p-button
            class="next-page-btn"
            [label]="nextStepButtonLabel[currentStep()]"
            (onClick)="navigateToNextScreen()"
            [disabled]="nextBtnDisabled()"
            [ngClass]="{
              'not-disable-btn': !nextBtnDisabled(),
            }"
          />
        }
      </div>
    </div>
  </div>
</div>
