<div class="modal-dialog">
  <div class="modal-body mb-4">
    <div class="flex align-items-center gap-2">
      <img [src]="type === 'danger' ? '/assets/exclamation-triangle.svg' : '/assets/exclamation-triangle-warning.svg'" class="modal-icon" />
      <div [innerHTML]="message"></div>
    </div>
  </div>
  <footer class="flex align-items-center justify-content-end mt-3 gap-3">
    <p-button styleClass="btn-secondary-gray" (click)="decline()">{{ cancelLabel }}</p-button>
    <p-button [styleClass]="type === 'danger' ? 'btn-primary' : 'btn-contrast'" (click)="confirm()">{{ confirmLabel }}</p-button>
  </footer>
</div>
