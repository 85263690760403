import { createAction, props } from '@ngrx/store';

export const setInputFields = createAction(
  '[Challenges] Set Input Fields',
  props<{
    isAllFieldSelected: boolean;
  }>(),
);

export const setAvailableSolutionSelectedFlag = createAction(
  '[Challenges] Set Available Solution Selected Flag',
  props<{
    isAvailableSolutionSelected: boolean;
  }>(),
);

export const setIsSelectSolutionFlag = createAction(
  '[Challenges] Set Select Solution Flag',
  props<{
    isIsSelectSolutionFlag: boolean;
  }>(),
);
