import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, filter, map, Observable, throwError } from 'rxjs';
import { IGeneralItem } from '../common/models/general.model';

export interface IResponseError {
  errorMessage: string;
  isError: boolean;
}

// for response which is an array
export interface IResponseArray extends IResponseError {
  results: IResponseValue[];
  totalCount: number;
}

// for response which is an object
export interface IResponseValue extends IResponseError {
  value: IGeneralItem;
}

export const customInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    filter((event) => event.type === HttpEventType.Response),
    map((resp) => {
      const { isError, errorMessage, ...data } = resp.body as IResponseError;
      if (!isError && !errorMessage) {
        if ('results' in data) {
          // if data has 'results' property, meaning that body is type of IResponseArray
          // return value property in each result item
          const { results, ...rest } = data as IResponseArray;
          return resp.clone({
            body: { ...rest, results: results.map(({ value }) => value) },
          });
        } else if ('value' in data) {
          // if data has 'value' property, meaning that body is type of IResponseValue
          // return value property in body
          return resp.clone({ body: (data as IResponseValue).value });
        }
      }

      // in case response has error
      throw new Error(errorMessage);
    }),
    catchError((error: HttpErrorResponse) => {
      console.error(`Error in request to api ${req.url}:`, error);
      return throwError(() => error);
    }),
  );
};
