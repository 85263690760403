export interface IQuickAccessItem {
  icon: string;
  name: QuickAccessCardNames;
  url?: string;
  iconExtend?: string;
  children?: IQuickAccessItemChildren[];
  expanded?: boolean;
}

export interface IQuickAccessItemChildren {
  name: QuickAccessCardNames;
  url: string;
}

export enum QuickAccessCardNames {
  Empty = '',
  SolutionAdditional = 'Solution Additional',
  ChallengeManagement = 'Challenge Management',
  SubChallengeManagement = 'Sub-Challenge Management',
  PSLManagement = 'PSL Management',
  DataEntryManagement = 'Data Entry Management',
  RoleManagement = 'Role Management',
  SessionManagement = 'Session Management',
  SolutionManagement = 'Solution Management',
  SolutionCategory = 'Solution Category',
  EntryCategory = 'Data Entry Category',
  EntryValue = 'Data Entry Value',
  ReferenceCategory = 'Reference Category',
  GateAction = 'Gate Action',
  GateActionCategory = 'Gate Action Category',
  CreateWorkflow = 'Create Workflow',
  ContributeWorkflow = 'Contribute Workflow',
  Reference = 'Reference',
  SolutionCondition = 'Solution Condition',
}

export const QUICK_ACCESS_ITEMS: IQuickAccessItem[] = [
  {
    icon: 'session-management-icon.svg',
    name: QuickAccessCardNames.SessionManagement,
    url: 'session',
  },
  {
    icon: 'user-management-icon.svg',
    name: QuickAccessCardNames.RoleManagement,
    url: 'role',
  },
  {
    icon: 'challenge-icon.svg',
    name: QuickAccessCardNames.ChallengeManagement,
    expanded: false,
    children: [
      {
        name: QuickAccessCardNames.PSLManagement,
        url: 'psl',
      },
      {
        name: QuickAccessCardNames.ChallengeManagement,
        url: 'challenge',
      },
      {
        name: QuickAccessCardNames.SubChallengeManagement,
        url: 'sub-challenge',
      },
    ],
  },
  {
    icon: 'solution-management-icon.svg',
    name: QuickAccessCardNames.SolutionManagement,
    expanded: false,
    children: [
      {
        name: QuickAccessCardNames.SolutionCategory,
        url: 'solution-category',
      },
      {
        name: QuickAccessCardNames.SolutionManagement,
        url: 'solution-management',
      },
    ],
  },
  {
    icon: 'data-entry-icon.svg',
    name: QuickAccessCardNames.DataEntryManagement,
    expanded: false,
    children: [
      {
        name: QuickAccessCardNames.EntryCategory,
        url: 'entry-category',
      },
      {
        name: QuickAccessCardNames.EntryValue,
        url: 'entry-value',
      },
    ],
  },
  {
    icon: 'info-icon.svg',
    name: QuickAccessCardNames.SolutionAdditional,
    expanded: false,
    children: [
      {
        name: QuickAccessCardNames.ReferenceCategory,
        url: 'reference-category',
      },
      {
        name: QuickAccessCardNames.Reference,
        url: 'reference',
      },
      {
        name: QuickAccessCardNames.GateActionCategory,
        url: 'gate-action-category',
      },
      {
        name: QuickAccessCardNames.GateAction,
        url: 'gate-action',
      },
      {
        name: QuickAccessCardNames.SolutionCondition,
        url: 'solution-condition',
      },
    ],
  },
];
