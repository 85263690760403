import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import {
  SelectInputSolutionFormKey,
  SolutionTitle,
} from 'src/app/core/common/constants/select-solution.enum';
import { setInputFields } from 'src/app/core/store/actions/select-solution.action';
import { selectPairChallenge } from 'src/app/core/store/selectors/select-challenge.selectors';
import { selectAvailableSolutionSelectedFlag } from 'src/app/core/store/selectors/select-solution.selectors';
import { PairChallenge } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.model';

@Component({
  selector: 'app-input-solutions',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    BrowserAnimationsModule,
    FormsModule,
    NgClass,
    InputTextModule,
  ],
  templateUrl: './input-solutions.component.html',
  styleUrl: './input-solutions.component.scss',
})
export class InputSolutionsComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);

  inputSolution!: FormGroup;
  fb = inject(FormBuilder);

  selectInputSolutionKeys = SelectInputSolutionFormKey;
  title = SolutionTitle;

  selectedChallenges = signal<PairChallenge[]>([]);
  isDisabled = signal(false);

  dropdownOptions: { [key: string]: { label: string; value: string }[] } = {
    'Fill Type': [
      { label: 'Consolidated Fill', value: 'fill1' },
      { label: 'Consolidated Fill option 2', value: 'fill2' },
    ],
    'Particle Type': [
      { label: 'Formation Fines', value: 'color1' },
      { label: 'Formation Fines option 2', value: 'color2' },
      { label: 'Formation Fines option 3', value: 'color3' },
    ],
    'Fill Location': [
      { label: 'Open Hole', value: 'shape1' },
      { label: 'Open Hole option 2', value: 'shape2' },
      { label: 'Open Hole option 3', value: 'shape3' },
    ],
    'Wellbore Deviation': [{ label: 'Vertical (< 30°)', value: 'size1' }],
    'Fluid in Wellbore': [{ label: 'Brine', value: 'texture1' }],
    'Downhole Temperature': [
      { label: 'F', value: 'F' },
      { label: 'C', value: 'C' },
      { label: 'K', value: 'K' },
    ],
    'Degree of Depletion': [
      { label: 'Holds Hydrostatic', value: 'depletion1' },
    ],
    Formation: [{ label: 'Carbonate', value: 'formation1' }],
  };

  dropdownOrder = [
    'Fill Type',
    'Particle Type',
    'Fill Location',
    'Wellbore Deviation',
    'Fluid in Wellbore',
    'Downhole Temperature',
    'Degree of Depletion',
    'Formation',
  ];

  // Temporary function for mock data support; will be removed after API integration.
  ngOnInit() {
    this.inputSolution = this.fb.group({});
    this.dropdownOrder.forEach((key) => {
      if (this.dropdownOptions[key]) {
        this.inputSolution.addControl(
          key,
          new FormControl(null, Validators.required),
        );
      }
    });

    this.getPairChallenge();

    this.inputSolution.valueChanges.subscribe((values) => {
      this.#globalStore.dispatch(
        setInputFields({
          isAllFieldSelected: this.inputSolution.valid,
        }),
      );
    });
    this.getSelectSolutionFlag();
  }

  getPairChallenge(): void {
    this.#globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.selectedChallenges.set(res.pairChallenges);
      });
  }

  // Temporary function for mock data support; will be removed after API integration.
  isDownholeTemperature(fieldKey: string): boolean {
    return fieldKey === this.selectInputSolutionKeys.DownholeTemperature;
  }

  // Temporary function for mock data support; will be removed after API integration.
  getSelectSolutionFlag(): void {
    this.#globalStore
      .select(selectAvailableSolutionSelectedFlag)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isDisabled.set(!!res.isAvailableSolutionSelected);
      });
  }
}
