import { createAction, props } from '@ngrx/store';
import { SelectedSolutions } from 'src/app/views/select-solutions/components/select-solutions-container/available-solutions/available-solutions.model';
import { DiagnosisSolutionCompatibility } from '../../common/models/workflow/diagnosis-solution';

export const setInputFields = createAction(
  '[Challenges] Set Input Fields',
  props<{
    isAllFieldSelected: boolean;
  }>(),
);

export const setIsAvailableSolutionSelected = createAction(
  '[Challenges] Set Available Solution Selected Flag',
  props<{
    isSelectedSolutions: boolean;
  }>(),
);

export const setIsSelectSolutionFlag = createAction(
  '[Challenges] Set Select Solution Flag',
  props<{
    isIsSelectSolutionFlag: boolean;
  }>(),
);

export const setSelectedSolutions = createAction(
  '[Challenges] Set Selected Solutions',
  props<{
    selectedSolutions: SelectedSolutions[];
  }>(),
);

export const setAvailableSolutions = createAction(
  '[Solutions] Get Available Solutions',
  props<{ availableSolutions: DiagnosisSolutionCompatibility[] }>(),
);
