import { NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromEvent, map, Subscription } from 'rxjs';
import { selectScreenState } from 'src/app/core/store/selectors/select-challenge.selectors';
import { ManageSessionService } from '../../../core/admin-features/services/diagnosis-session/manage-session.service';
import { HeaderComponent } from '../../../core/common/components/header/header.component';
import { StepMasterComponent } from '../../../core/common/components/step-master/step-master.component';
import {
  CanComponentDeactivate,
  CanDeactivateType,
} from '../../../core/common/models/general.model';

@Component({
  selector: 'app-user-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StepMasterComponent, NgClass],
  templateUrl: './user-layout.component.html',
  styleUrl: './user-layout.component.scss',
})
export class UserLayoutComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);

  isInitializeDiagnosisScreen = signal(false);

  sessionService = inject(ManageSessionService);
  activeRoute = inject(ActivatedRoute);
  sessionId: string = '';
  private _sub: Subscription = new Subscription();

  constructor() {
    this.activeRoute.firstChild?.params
      .pipe(takeUntilDestroyed())
      .subscribe((param) => {
        this.sessionId = param['sid'] || '';
      });
  }

  ngOnInit(): void {
    this.getScreenState();
    this._sub = fromEvent(window, 'beforeunload').subscribe(() => {
      this.canDeactivate();
    });
  }

  getScreenState(): void {
    this.#globalStore
      .select(selectScreenState)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isInitializeDiagnosisScreen.set(res);
      });
  }

  canDeactivate(): CanDeactivateType {
    if (!this.sessionId) return true;
    return this.sessionService
      .unblock(this.sessionId)
      .pipe(map((resp) => !resp.isBlocking));
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
