import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { ButtonLabels } from 'src/app/core/common/enums/select-solution.enum';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { setIsDesignApproved } from 'src/app/core/store/actions/required-action.actions';
import { navigateToNextStep } from 'src/app/core/store/actions/router.action';
import {
  setChallenges,
  setScreenStates,
} from 'src/app/core/store/actions/select-challenge.actions';
import {
  setAvailableSolutions,
  setInputFields,
  setIsAvailableSolutionSelected,
  setIsSelectSolutionFlag,
} from 'src/app/core/store/actions/select-solution.action';
import { selectPairChallenge } from 'src/app/core/store/selectors/select-challenge.selectors';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import { REQUEST_PARAM, ROUTES } from '../../constants/routes.constant';
import { CURRENT_STATUS } from '../../constants/status.constant';
import { DiagnosisSession } from '../../models/workflow/diagnosis-session';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgOptimizedImage, ButtonModule, NgClass],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  readonly #globalStore = inject(Store);
  readonly globalStore = inject(Store);
  readonly #destroyRef = inject(DestroyRef);
  readonly #authService = inject(MsalService);
  readonly #router = inject(Router);
  readonly #storeService = inject(StoreService);
  readonly #diagnosisSessionService = inject(DiagnosisSessionService);

  userName = '';
  buttonLabel = ButtonLabels;
  pageRoute = ROUTES;
  status = CURRENT_STATUS;

  isInitializeDiagnosisScreen = signal(false);
  isSelectSolutionScreen = signal(false);
  isViewSummaryScreen = signal(false);
  isRequiredActionScreen = signal(false);
  isDisableInitializeBtn = signal(false);
  isDisableViewSummaryBtn = signal(false);
  isHideApprovedForDesignBtn = signal(false);
  isDisableApprovedForDesignBtn = signal(false);
  diagnosisSessionId = signal<string>('');
  pairChallenges = signal<PairChallenge[]>([]);

  externalId?: string;
  diagnosisSessionStatus?: string = '';
  currentPageUrl: string = '';
  diagnosisSession?: DiagnosisSession;

  ngOnInit(): void {
    this.verifySessionRequest();
    this.getActiveAccountInfo();
    this.getPairChallenge();
    this.getSelectedSolution();
    this.processRouteActions();
    this.getRequiredCheckboxSelected();
  }

  verifySessionRequest() {
    this.#storeService.getExternalId().subscribe((eid) => {
      this.externalId = eid;
      if (this.externalId) {
        this.#diagnosisSessionService
          .getByExternalId(this.externalId)
          .pipe(takeUntilDestroyed(this.#destroyRef))
          .subscribe({
            next: (res) => this.updateSessionData(res),
            error: (err) => {
              this.resetDiagnosisStatus();
            },
          });
      }
    });

    this.#storeService.getSessionId().subscribe((sid) => {
      this.diagnosisSessionId.set(sid);
      if (this.diagnosisSessionId()) this.loadDiagnosisSession();
    });
  }

  loadDiagnosisSession() {
    this.#diagnosisSessionService
      .getById(this.diagnosisSessionId()!)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (res) => this.updateSessionData(res),
        error: (err) => {
          this.resetDiagnosisStatus();
        },
      });
  }

  updateSessionData(session: DiagnosisSession) {
    if (session) {
      this.diagnosisSession = session;
      this.diagnosisSessionId.set(session.id ?? '');
      this.diagnosisSessionStatus = session.diagnosisStatus.name ?? '';
      console.log(this.diagnosisSessionStatus);

      this.isHideApprovedForDesignBtn.set(
        this.diagnosisSession?.diagnosisStatus?.name ===
          this.status.APPROVED_FOR_DESIGN && this.isRequiredActionScreen(),
      );
      this.gotoPage(this.diagnosisSessionStatus);
    } else {
      this.resetDiagnosisStatus();
    }
  }

  resetDiagnosisStatus(): void {
    this.diagnosisSession = undefined;
    this.diagnosisSessionId.set('');
    this.diagnosisSessionStatus = '';
    this.isInitializeDiagnosisScreen.set(true);
  }

  getActiveAccountInfo(): void {
    const activeAccount = this.#authService.instance.getActiveAccount();
    this.userName = activeAccount?.name ? activeAccount?.name : '';
  }

  logOut(): void {
    this.#authService.logoutRedirect();
  }

  processRouteActions(): void {
    let lastRoute = '';
    const initializeDiagnosisScreen = () => {
      this.isInitializeDiagnosisScreen.set(true);
      this.isDisableInitializeBtn.set(true);
    };
    const routeActions = {
      [this.pageRoute.SELECT_CHALLENGES]: initializeDiagnosisScreen,
      [this.pageRoute.SELECT_SOLUTIONS]: () =>
        this.isSelectSolutionScreen.set(true),
      [this.pageRoute.VIEW_SUMMARY]: () => {
        this.isViewSummaryScreen.set(true);
        this.isDisableInitializeBtn.set(false);
      },
      [this.pageRoute.REQUIRED_ACTIONS]: () =>
        this.isRequiredActionScreen.set(true),
    };

    this.#router.events
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        const routeKey = Object.keys(routeActions).find((route) =>
          this.#router.url.includes(route),
        );
        if (routeKey && routeKey !== lastRoute) {
          routeActions[routeKey]();
          lastRoute = routeKey;
          this.currentPageUrl = lastRoute;
          if (this.isInitializeDiagnosisScreen()) this.dispatchScreenState();
        }
      });
  }

  handleRouterNavigate(): void {
    const dispatchNavigate = (
      pageRoute: string,
      sessionId: string,
      externalId?: string,
    ) => {
      this.globalStore.dispatch(
        navigateToNextStep({
          pageRoute,
          sessionId,
          externalId,
        }),
      );
    };
    switch (true) {
      case this.isInitializeDiagnosisScreen():
        dispatchNavigate(
          this.pageRoute.SELECT_CHALLENGES,
          this.diagnosisSessionId(),
        );
        break;
      case this.isSelectSolutionScreen():
        dispatchNavigate(
          this.pageRoute.SELECT_SOLUTIONS,
          this.diagnosisSessionId(),
        );
        break;
      case this.isViewSummaryScreen():
        dispatchNavigate(
          this.pageRoute.VIEW_SUMMARY,
          this.diagnosisSessionId(),
        );
        break;
      case this.isRequiredActionScreen():
        dispatchNavigate(
          this.pageRoute.REQUIRED_ACTIONS,
          this.diagnosisSessionId(),
        );
        break;
    }
  }

  navigateToNextScreen(): void {
    if (this.isInitializeDiagnosisScreen()) {
      this.initializeSession();
    } else if (this.isSelectSolutionScreen()) {
      this.isViewSummaryScreen.set(true);
      this.isSelectSolutionScreen.set(false);
      this.handleRouterNavigate();
    } else if (this.isViewSummaryScreen()) {
      this.completeDiagnosis();
    }

    if (
      this.isRequiredActionScreen() &&
      !this.isDisableApprovedForDesignBtn()
    ) {
      this.approveForDesign();
    }
  }

  backToPreviousScreen(): void {
    if (this.isRequiredActionScreen()) {
      this.isRequiredActionScreen.set(false);
      this.isViewSummaryScreen.set(true);
    } else if (this.isViewSummaryScreen()) {
      this.isViewSummaryScreen.set(false);
      this.isSelectSolutionScreen.set(true);
    }
    this.handleRouterNavigate();
  }

  gotoPage(status: string = '') {
    let nextPageUrl: string = '';
    switch (status) {
      case CURRENT_STATUS.IN_PROGRESS:
        if (this.isCurrentPage(ROUTES.VIEW_SUMMARY))
          nextPageUrl = `${ROUTES.VIEW_SUMMARY}/${this.diagnosisSessionId()}`;
        else
          nextPageUrl = `${ROUTES.SELECT_SOLUTIONS}/${this.diagnosisSessionId()}`;
        break;
      case CURRENT_STATUS.APPROVED_FOR_DESIGN:
        nextPageUrl = `${ROUTES.REQUIRED_ACTIONS}/${this.diagnosisSessionId()}`;
        this.isHideApprovedForDesignBtn.set(true);
        break;
      case CURRENT_STATUS.DIAGNOSIS_COMPLETED:
        nextPageUrl = `${ROUTES.REQUIRED_ACTIONS}/${this.diagnosisSessionId()}`;
        break;
      default:
        nextPageUrl = this.externalId
          ? `${REQUEST_PARAM.ExternalId}/${this.externalId}`
          : '';
    }

    this.#router.navigate([nextPageUrl]);
  }

  isCurrentPage(pageUrl: string | undefined = undefined): boolean {
    return pageUrl ? this.currentPageUrl === pageUrl : this.isRootPage();
  }

  isRootPage(): boolean {
    return (
      this.currentPageUrl === '' ||
      this.currentPageUrl === this.pageRoute.SELECT_CHALLENGES ||
      this.currentPageUrl === REQUEST_PARAM.ExternalId ||
      this.currentPageUrl === REQUEST_PARAM.SessionId
    );
  }

  setNextStepButtonLabel(): string {
    if (this.isSelectSolutionScreen()) return this.buttonLabel.ViewSummary;
    if (this.isViewSummaryScreen()) return this.buttonLabel.CompleteDiagnosis;
    if (this.isRequiredActionScreen())
      return this.buttonLabel.ApprovedForDesign;
    return this.buttonLabel.InitializeDiagnosis;
  }

  getPairChallenge(): void {
    this.#globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.pairChallenges.set(res);
        this.isDisableInitializeBtn.set(!res.length);
      });
  }

  dispatchScreenState(): void {
    this.#globalStore.dispatch(
      setScreenStates({
        isInitializeDiagnosisScreen: this.isInitializeDiagnosisScreen(),
      }),
    );
  }

  dispatchIsSelectSolutionFlag(options: { isEnable: boolean }) {
    this.#globalStore.dispatch(
      setIsSelectSolutionFlag({
        isIsSelectSolutionFlag: options.isEnable,
      }),
    );
  }

  restartWorkflow(): void {
    this.#diagnosisSessionService
      .restartSession(this.diagnosisSessionId()!)
      .subscribe((res) => this.resetWorkflowState());
  }

  resetWorkflowState() {
    this.initializeWorkflowStates();
    [
      this.isSelectSolutionScreen,
      this.isViewSummaryScreen,
      this.isRequiredActionScreen,
    ].forEach((screen) => screen.set(false));
    this.resetDiagnosisStatus();
    this.handleRouterNavigate();
  }

  dispatchIsDesignApproved(): void {
    console.log('dispatchIsDesignApproved');
    this.#globalStore.dispatch(
      setIsDesignApproved({
        isDesignApproved: true,
      }),
    );
  }

  isNextBtnDisabled(): boolean {
    switch (true) {
      case this.isSelectSolutionScreen():
        return this.isDisableViewSummaryBtn();
      case this.isRequiredActionScreen():
        return this.isDisableApprovedForDesignBtn();
      default:
        return this.isDisableInitializeBtn();
    }
  }

  getSelectedSolution(): void {
    this.#storeService.getSelectedSolution().subscribe((res) => {
      this.isDisableViewSummaryBtn.set(!res);
    });
  }

  getRequiredCheckboxSelected(): void {
    this.#storeService.getRequiredCheckboxSelected().subscribe((res) => {
      if (this.diagnosisSessionStatus === this.status.APPROVED_FOR_DESIGN) {
        this.isDisableApprovedForDesignBtn.set(true);
      } else {
        this.isDisableApprovedForDesignBtn.set(!res);
      }
    });
  }

  getTagClassName(currentStatus: string | undefined): string {
    switch (currentStatus) {
      case this.status.APPROVED_FOR_DESIGN:
        return 'approved-tag';
      case this.status.DIAGNOSIS_COMPLETED:
        return 'completed-tag';
      default:
        return '';
    }
  }

  initializeWorkflowStates(): void {
    this.#globalStore.dispatch(
      setChallenges({
        pairChallenges: [],
      }),
    );

    this.#globalStore.dispatch(
      setInputFields({
        isAllFieldSelected: false,
      }),
    );

    this.#globalStore.dispatch(
      setIsAvailableSolutionSelected({
        isSelectedSolutions: false,
      }),
    );

    this.#globalStore.dispatch(
      setAvailableSolutions({
        availableSolutions: [],
      }),
    );
  }

  initializeSession() {
    const subChallengeIds = this.pairChallenges().map(
      (item) => item.subChallengeId!,
    );

    this.#diagnosisSessionService
      .initializeSession(this.externalId ?? '', subChallengeIds)
      .subscribe((res: any) => {
        this.diagnosisSessionId.set(res?.value?.id);
        this.diagnosisSession = res?.value;
        this.diagnosisSessionStatus =
          this.diagnosisSession?.diagnosisStatus.name;
        console.log(this.diagnosisSessionStatus);

        this.isSelectSolutionScreen.set(true);
        this.isInitializeDiagnosisScreen.set(false);
        this.handleRouterNavigate();
        this.dispatchScreenState();
      });
  }

  completeDiagnosis() {
    this.#diagnosisSessionService
      .completeSession(this.diagnosisSessionId()!)
      .subscribe((res: any) => {
        this.diagnosisSession = res?.value;
        this.diagnosisSessionStatus = res.value.diagnosisStatusName;

        this.isRequiredActionScreen.set(true);
        this.isViewSummaryScreen.set(false);
        this.handleRouterNavigate();
        this.dispatchScreenState();
      });
  }

  approveForDesign() {
    this.#diagnosisSessionService
      .approveSession(this.diagnosisSessionId()!)
      .subscribe((res) => {
        this.getRequiredCheckboxSelected();
        this.isHideApprovedForDesignBtn.set(true);
        this.dispatchIsDesignApproved();
        this.handleRouterNavigate();
        this.dispatchScreenState();
        this.loadDiagnosisSession();
      });
  }
}
