import { NgClass } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { SummarySectionTypes } from 'src/app/core/common/enums/select-solution.enum';
import {
  GateAction,
  Reference,
} from 'src/app/core/common/models/master-data/metadata';
import { DiagnosisSessionService } from 'src/app/core/services/diagnosis-session.service';
import { DiagnosisSolutionService } from 'src/app/core/services/diagnosis-solution.service';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { UrlTransformPipe } from '../../../../../core/common/pipe/url-transform.pipe';
import {
  GateActionsByCate,
  ReferencesByCate,
  SolutionMessage,
  SummarySection,
} from './diagnosis-summary-view.model';
import { DiagnosisSummaryViewStore } from './diagnosis-summary-view.store';

@Component({
  selector: 'app-diagnosis-summary-view',
  standalone: true,
  imports: [
    TableModule,
    NgClass,
    ButtonModule,
    BlockUIModule,
    UrlTransformPipe,
  ],
  providers: [DiagnosisSummaryViewStore],
  templateUrl: './diagnosis-summary-view.component.html',
  styleUrls: ['./diagnosis-summary-view.component.scss'],
})
export class DiagnosisSummaryViewComponent {
  store = inject(DiagnosisSummaryViewStore);
  activeRoute = inject(ActivatedRoute);
  storeService = inject(StoreService);
  diagnosisSessionService = inject(DiagnosisSessionService);
  diagnosisSolutionService = inject(DiagnosisSolutionService);

  sectionTypes = SummarySectionTypes;
  diagnosisSummary: SummarySection[] = [];

  summaryColumn = [{ header: 'groupHeading' }];
  expandedRows: Record<string, boolean> = {};

  solutionMessages = signal<SolutionMessage[]>([]);
  currentIndex = signal(0);
  suggestedGateActions: GateActionsByCate[] = [];
  diagnosisReferences: ReferencesByCate[] = [];

  loadSelectedSolutions = effect(() => {
    if (this.store.solutionMessages()?.length) {
      this.addSectionContent(
        this.sectionTypes.SolutionMessage,
        1,
        this.store.solutionMessages(),
      );
      this.expandedRows[this.sectionTypes.SolutionMessage] = true;
    }
  });

  loadSuggestedRequiredActions = effect(() => {
    if (this.store.suggestedRequiredActions()?.length) {
      this.suggestedGateActions = this.groupGateActions(
        this.store.suggestedRequiredActions(),
      );
      this.addSectionContent(
        this.sectionTypes.GateAction,
        2,
        this.suggestedGateActions,
      );
      this.expandedRows[this.sectionTypes.GateAction] = true;
    }
  });

  loadDiagnosisReferences = effect(() => {
    if (this.store.diagnosisRefrences()?.length) {
      this.diagnosisReferences = this.groupReferences(
        this.store.diagnosisRefrences(),
      );
      this.addSectionContent(
        this.sectionTypes.Reference,
        3,
        this.diagnosisReferences,
      );
      this.expandedRows[this.sectionTypes.Reference] = true;
    }
  });

  expandDiagnosisSummary(): void {
    this.diagnosisSummary.forEach((diagnosisSummary) => {
      this.expandedRows[diagnosisSummary.groupHeading] = true;
    });
    this.expandedRows = Object.assign({}, this.expandedRows);
  }

  addSectionContent(groupHeading: string, orderBy: number, data: any[]): void {
    if (data.length > 0) {
      this.diagnosisSummary.push({
        groupHeading,
        orderBy,
        items: data,
      });
      this.diagnosisSummary.sort((a, b) => a.orderBy - b.orderBy);
    }
  }

  swicthTab(index: number): void {
    this.currentIndex.set(index);
  }

  groupGateActions(requiredActions: GateAction[]): GateActionsByCate[] {
    //First, group the required actions by category
    if (requiredActions.length) {
      const group = requiredActions.reduce(
        (acc: { [key: string]: GateAction[] }, current: GateAction) => {
          const key = current.gateActionCategoryName;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(current);
          return acc;
        },
        {},
      );

      //Get the categories and required actions related.
      return Object.keys(group).map((key) => ({
        category: key,
        actions: group[key].sort((a, b) => a.name.localeCompare(b.name)),
      }));
    }
    return [];
  }

  groupReferences(references: Reference[]): ReferencesByCate[] {
    //First, group the references by category
    const group = references.reduce(
      (acc: { [key: string]: Reference[] }, current: Reference) => {
        const key = current.referenceCategoryName;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(current);
        return acc;
      },
      {},
    );

    //Get the categories and references related.
    return Object.keys(group).map((key) => ({
      category: key,
      references: group[key],
    }));
  }
}
