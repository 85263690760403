import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { map, pipe, switchMap } from 'rxjs';
import { AdminState } from '../../../views/admin-features/common/admin-dashboard/admin-dashboard.model';
import { QUICK_ACCESS_ITEMS } from '../../../views/admin-features/common/admin-dashboard/components/quick-access-card/quick-access-card.model';
import { IStatisticsItem } from '../../../views/admin-features/common/admin-dashboard/components/statistics/statistics.model';
import { RoleService } from '../services/authorization/role.service';
import { GeneralService } from '../services/common/general.service';

const initialState: AdminState = {
  statisticsItems: [],
  quickAccessItems: [],
  menuExpanded: false,
};

export const AdminStore = signalStore(
  withDevtools('admin-dashboard'),
  withState(initialState),
  withMethods(
    (
      store,
      generalService = inject(GeneralService),
      roleService = inject(RoleService),
    ) => ({
      getStatisticValues: rxMethod<void>(
        pipe(
          switchMap(() =>
            generalService.getStatisticInfo().pipe(
              map((statisticsInfo) => {
                return [
                  {
                    title: 'Active Users',
                    mainValue: statisticsInfo.numOfActiveUsers,
                  },
                  {
                    title: 'Sessions',
                    mainValue: statisticsInfo.numOfSessions,
                    topValue: {
                      name: 'Approved for design',
                      value: statisticsInfo.numOfApprovedSessions,
                    },
                    bottomValue: {
                      name: 'Diagnosis Completed',
                      value: statisticsInfo.numOfCompletedSessions,
                    },
                  },
                  {
                    title: 'Challenges',
                    mainValue: statisticsInfo.numOfChallenges,
                    topValue: {
                      name: 'Sub-challenges',
                      value: statisticsInfo.numOfSubChallenges,
                    },
                  },
                  {
                    title: 'Solutions',
                    mainValue: statisticsInfo.numOfSolutions,
                  },
                ] as IStatisticsItem[];
              }),
              tapResponse(
                (statisticsItems) => {
                  patchState(store, {
                    statisticsItems,
                  });
                },
                (error: HttpErrorResponse) => {
                  console.error(
                    'An error occurred in getStatisticValues',
                    error,
                  );
                },
              ),
            ),
          ),
        ),
      ),
      getAccessItemsByUserPermissions: rxMethod<void>(
        pipe(
          switchMap(() =>
            roleService.getUserPermissions().pipe(
              map((res) => {
                return QUICK_ACCESS_ITEMS.filter((card) =>
                  res.find((permission) => card.name === permission.name),
                );
              }),
              tapResponse(
                (quickAccessItems) =>
                  patchState(store, {
                    quickAccessItems,
                  }),
                (error: HttpErrorResponse) => {
                  console.error(
                    'An error occurred in getUserPermissions',
                    error,
                  );
                },
              ),
            ),
          ),
        ),
      ),
      toggleMenuExpanded() {
        patchState(store, {
          menuExpanded: !store.menuExpanded(),
        });
      },
    }),
  ),
);
