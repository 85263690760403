import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { ArrayResult } from '../common/models/api-response';
import {
  DiagnosisSolutionCompatibility,
  DiagnosisSolutionCreateDto,
  DiagnosisSolutionGetDto,
} from '../common/models/workflow/diagnosis-solution';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisSolutionService {
  private http = inject(HttpClient);
  baseUrl = environment.api;

  selectSolution(
    solution: DiagnosisSolutionCreateDto,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .post<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.selectSolution, solution)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  unselectSolution(
    solution: DiagnosisSolutionGetDto,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .post<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.unselectSolution, solution)
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  resetSolutionSelection(
    sessionId: string,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.resetSolutionSelection.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getAvailableSolutions(
    sessionId: string,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.getAvailableSolutions.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getSelectedSolutions(
    sessionId: string,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.getSelectedSolutions.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }

  getCombinedCompatibilities(
    sessionId: string,
  ): Observable<DiagnosisSolutionCompatibility[]> {
    return this.http
      .get<
        ArrayResult<DiagnosisSolutionCompatibility>
      >(this.baseUrl + apiConfig.diagnosisSolution.getCombinedCompatibilities.replace(':sessionId', sessionId))
      .pipe(
        map((sub) => {
          return sub && sub.results.map((c) => c.value);
        }),
      );
  }
}
