import { NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import {
  ChallengeName,
  ChallengeType,
} from 'src/app/core/common/enums/select-challenge.enum';
import { SortAlphabetPipe } from 'src/app/core/common/pipe/sort-alphabet.pipe';
import { StoreService } from 'src/app/core/services/global-store/store.service';
import { ChallengeListComponent } from './challenge-list/challenge-list.component';
import {
  ChallengeResult,
  PairChallenge,
  SubChallenge,
} from './challenges-sub-challenges.model';
import { ChallengesSubChallengesStore } from './challenges-sub-challenges.store';

@Component({
  selector: 'app-challenges-sub-challenges',
  standalone: true,
  imports: [
    ButtonModule,
    NgClass,
    TableModule,
    ChallengeListComponent,
    SortAlphabetPipe,
  ],
  providers: [ChallengesSubChallengesStore],
  templateUrl: './challenges-sub-challenges.component.html',
  styleUrl: './challenges-sub-challenges.component.scss',
})
export class ChallengesSubChallengesComponent {
  readonly store = inject(ChallengesSubChallengesStore);
  readonly #storeService = inject(StoreService);

  filteredSubChallenges = signal<SubChallenge[]>([]);
  challengeType = ChallengeType;
  challengeName = ChallengeName;
  selectedChallenge = signal<ChallengeResult>(
    this.store.initialChallengeResult(),
  );
  selectedSubChallenge = signal<SubChallenge>(this.store.initialSubChallenge());
  pairChallenges = signal<PairChallenge[]>([]);

  handleChallengeSelection(
    selectedChallenge: ChallengeResult | SubChallenge,
    challengeType: string,
  ): void {
    if (challengeType === ChallengeType.Challenge) {
      const challenge = selectedChallenge as ChallengeResult;
      this.filteredSubChallenges.set(challenge?.value?.subChallenges ?? []);
      this.selectedChallenge.set(challenge);
      this.resetToDefaultSubChallenge();
      this.getPairChallenge();
      return;
    }

    this.selectedSubChallenge.set(selectedChallenge as SubChallenge);
  }

  disableAddPairChallengeBtn(): boolean {
    return !(
      this.selectedChallenge()?.value.name && this.selectedSubChallenge()?.name
    );
  }

  addPairChallenge(): void {
    if (!this.selectedChallenge() || !this.selectedSubChallenge()) {
      return;
    }
    this.getPairChallenge();
    this.pairChallenges.update((currentChallenges = []) => [
      ...(Array.isArray(currentChallenges) ? currentChallenges : []),
      {
        challengeName: this.selectedChallenge().value.name,
        subChallengeId: this.selectedSubChallenge().id,
        subChallengeName: this.selectedSubChallenge().name,
      },
    ]);

    this.#storeService.dispatchPairChallenge(this.pairChallenges());
    this.resetToDefaultSubChallenge();
  }

  getPairChallenge(): void {
    this.#storeService.getPairChallenge().subscribe((pairChallenge) => {
      this.pairChallenges.set(pairChallenge);
      this.disableUsedSubChallenges();
      this.setInInitalChallenges();
    });
  }

  disableUsedSubChallenges(): void {
    const usedSubChallenges = new Set(
      this.pairChallenges().map((item) => item.subChallengeName),
    );

    this.filteredSubChallenges.update((challenges) =>
      challenges.map((challenge: SubChallenge) => ({
        ...challenge,
        isDisabled: usedSubChallenges.has(challenge.name),
      })),
    );
  }

  resetToDefaultSubChallenge(): void {
    this.selectedSubChallenge.set(this.store.initialSubChallenge());
  }

  setInInitalChallenges(): void {
    this.store.setInInitialChallenge(this.pairChallenges());
  }
}
