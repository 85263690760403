import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { Menu } from 'primeng/menu';
import { AdminStore } from '../../../../core/admin-features/store/admin.store';

@Component({
  selector: 'app-admin-header',
  standalone: true,
  imports: [AvatarModule, Menu],
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminHeaderComponent implements OnInit {
  readonly store = inject(AdminStore);
  readonly #authService = inject(MsalService);
  readonly #router = inject(Router);
  avatarLabel = '';
  items: MenuItem[] = [
    {
      label: 'User Page',
      command: () => {
        this.#router.navigate(['/']);
      },
    },
    {
      label: 'Log out',
      styleClass: 'log-out',
      command: () => {
        this.logOut();
      },
    },
  ];

  ngOnInit(): void {
    this.store.getAccessItemsByUserPermissions();
    this.getAvatarLabel();
  }

  toggleMenu() {
    this.store.toggleMenuExpanded();
  }

  private getAvatarLabel() {
    const activeAccount = this.#authService.instance.getActiveAccount();
    //Get first letter from user name
    this.avatarLabel = activeAccount?.name
      ? activeAccount?.name
          .split(' ')
          .map((item) => item[0])
          .join('')
          .substring(0, 2)
      : '';
  }

  private logOut() {
    this.#authService.logoutRedirect();
  }
}
