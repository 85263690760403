<div class="container">
  <div>
    <app-review-selected-challanges></app-review-selected-challanges>
  </div>

  <div class="input">
    <div>
      <div class="input_title inline-block">
        <h2>{{ title.Inputs }}</h2>
      </div>

      <div class="clear-all-wrapper inline-block">
        <p-button
          class="clear-all-button"
          label="Clear All"
          (onClick)="clearAllSelections()"
          [ngClass]="{ 'clear-btn-disabled': !isEnableClearAllBtn() }"
          [disabled]="!isEnableClearAllBtn()"
        />
      </div>
    </div>

    <form id="input-form" [formGroup]="inputSolution" class="input-form">
      @for (cate of entryCategories; track $index; let idx = $index) {
        @if (requiredInputs[cate.name]) {
          @let isInputControlValid = isInputControl(cate.inputType.uiControlName!);

          <div class="input-solution" [ngClass]="{ 'input-temperature-solution': isInputControlValid }">
            <label [for]="cate.name">{{ cate.name }}:</label>
            @if (isInputControlValid) {
              <div class="input-container">
                <input
                  type="number"
                  [id]="cate.name"
                  [name]="cate.name"
                  formControlName="input{{ cate.name }}"
                  pInputText
                  placeholder="Input number"
                  class="input-solution_field w-full"
                  [ngClass]="{ 'disabled-dropdown': isDisabledDropdown() }"
                  (change)="onTextInputChanged($event, cate)"
                />
              </div>
            }
            <p-select
              appendTo="body"
              [id]="cate.name"
              [formControlName]="cate.name"
              [options]="requiredInputs[cate.name]"
              [placeholder]="getDefaultValue(cate)"
              styleClass="input-solution-select"
              (onChange)="onDropdownChanged($event, cate)"
              [ngClass]="{
                'temperature-select': isInputControlValid,
                'disabled-select': isDisabledDropdown(),
              }"
              [showClear]="isInputControlValid ? false : true"
            />
          </div>
        }
      }
    </form>
  </div>
</div>
