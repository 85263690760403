import { NgClass } from '@angular/common';
import { Component, DestroyRef, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { setChallenges } from 'src/app/core/store/actions/select-challenge.actions';
import { selectPairChallenge } from 'src/app/core/store/selectors/select-challenge.selectors';
import { PairChallenge } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.model';

@Component({
  selector: 'app-selected-pair-challenges',
  standalone: true,
  imports: [ButtonModule, NgClass],
  templateUrl: './selected-pair-challenges.component.html',
  styleUrl: './selected-pair-challenges.component.scss',
})
export class SelectedPairChallengesComponent {
  globalStore = inject(Store);
  pairChallenges = signal<PairChallenge[]>([]);
  #destroyRef = inject(DestroyRef);

  constructor() {
    effect(
      () => {
        this.getPairChallenge();
      },
      { allowSignalWrites: true },
    );
  }

  getPairChallenge(): void {
    this.globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.pairChallenges.set(res.pairChallenges);
      });
  }

  handleDisable(): boolean {
    return !!this.pairChallenges().length;
  }

  removePairChallenge(challengeName: string): void {
    this.pairChallenges.update((challenges) =>
      challenges.filter(
        (challenge) => challenge.subChallengeName !== challengeName,
      ),
    );
    this.globalStore.dispatch(
      setChallenges({
        pairChallenges: this.pairChallenges(),
      }),
    );
  }

  removeAllPairs(): void {
    this.pairChallenges.set([]);
    this.globalStore.dispatch(
      setChallenges({
        pairChallenges: [],
      }),
    );
  }
}
