import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectModule } from 'primeng/select';
import { UserSettingService } from '../../../../../core/admin-features/services/diagnosis-session/user-setting.service';
import { LoadingDirective } from '../../../../../core/common/directives/loading/loading.directive';
import { toCamelCase } from '../../../../../core/common/models/general.model';
import {
  IUoMSetting,
  IUserSetting,
} from '../../../../../core/common/models/workflow/user-setting';

@Component({
  selector: 'app-uom-setting',
  standalone: true,
  imports: [
    SelectModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingDirective,
  ],
  templateUrl: './uom-setting.component.html',
  styleUrl: './uom-setting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UomSettingComponent implements OnInit {
  settingService = inject(UserSettingService);
  toastr = inject(MessageService);
  form: FormGroup | undefined;

  userSetting = signal<IUserSetting>({ username: '', uoMSettings: [] });

  constructor(private dialogRef: DynamicDialogRef) {}

  ngOnInit(): void {
    this.settingService
      .getCurrentUoMSetting()
      .subscribe((resp: IUserSetting) => {
        if (resp) {
          this.userSetting.set({
            ...resp,
            uoMSettings: resp.uoMSettings.map((setting) => ({
              ...setting,
              key: toCamelCase(setting.uoMCategoryName),
              options: setting.uoMs?.map((uom) => ({
                label: uom.name,
                value: uom.id,
              })),
            })),
          });
          this.form = this.toFormGroup(this.userSetting().uoMSettings);
        }
      });
  }

  resetToDefault() {
    const defaultValues = this.userSetting().uoMSettings.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key!]: curr.uoMs?.find((x) => x.isDefault)?.id,
      }),
      {},
    );

    this.form?.setValue(defaultValues);
    this.form?.markAsDirty();
  }

  apply() {
    const payload = {
      username: this.userSetting().username,
      uoMSettings: this.userSetting().uoMSettings.map((setting) => ({
        uoMcategoryId: setting.uoMcategoryId,
        selectedUoMId: this.form?.get(setting.key!)?.value || undefined,
      })),
    };

    this.settingService.applyUoMSetting(payload).subscribe((res) => {
      if (res) {
        this.toastr.add({
          severity: 'success',
          summary: 'Update Success',
          detail: 'The UoM Settings has been updated successfully.',
          closable: true,
        });
        this.close();
      } else {
        this.toastr.add({
          severity: 'error',
          summary: 'Error',
          detail: `Error in updating the UoM Settings. Please try again later.`,
          closable: true,
        });
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  private toFormGroup(uomCates: IUoMSetting[]) {
    const group = uomCates.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key || '']: new FormControl(
          curr.selectedUoMId,
          Validators.required,
        ),
      };
    }, {});
    return new FormGroup(group);
  }
}
