import { Observable } from 'rxjs';
import {
  IGeneralItem,
  IPagination,
  IPagingResponse,
} from '../../common/models/general.model';
import { ChallengeStoreType } from '../store/challenge.store';
import { EntryCategoryStoreType } from '../store/entry-category.store';
import { EntryValueStoreType } from '../store/entry-value.store';
import { GateActionCategoryStoreType } from '../store/gate-action-category.store';
import { GateActionStoreType } from '../store/gate-action.store';
import { PslStoreType } from '../store/psl.store';
import { ReferenceCategoryStoreType } from '../store/reference-category.store';
import { ReferenceStoreType } from '../store/reference.store';
import { RoleStoreType } from '../store/role.store';
import { SessionStoreType } from '../store/session.store';
import { SolutionCategoryStoreType } from '../store/solution-category.store';
import { SolutionConditionStoreType } from '../store/solution-condition.store';
import { SolutionManagementStoreType } from '../store/solution-management.store';
import { SubChallengeStoreType } from '../store/sub-challenge.store';

export enum AdminFeature {
  CHALLENGE_MANAGEMENT = 'challengeManagement',
  ENTRY_CATEGORY_MANAGEMENT = 'entryCategoryManagement',
  ENTRY_VALUE_MANAGEMENT = 'entryValueManagement',
  ROLE_MANAGEMENT = 'roleManagement',
  SESSION_MANAGEMENT = 'sessionManagement',
  SOLUTION_MANAGEMENT = 'solutionManagement',
  SOLUTION_COMPATIBILITY = 'solutionCompatibilityListing',
  SUB_CHALLENGE_MANAGEMENT = 'subChallengeManagement',
  SOLUTION_RELEVANCE_LISTING = 'solutionRelevanceListing',
  ENTRY_JOB_PHASE_MANAGEMENT = 'entryJobPhaseManagement',
  PSL_MANAGEMENT = 'pslManagement',
  SOLUTION_CATEGORY = 'solutionCategory',
  SOLUTION_CONDITION = 'solutionCondition',
  DATA_ENTRY_COMPATIBILITY = 'dataEntryCompatibility',
  REFERENCE_CATEGORY = 'referenceCategory',
  REFERENCE = 'reference',
  SOLUTION_RERERENCE_LISTING = 'solutionReferenceListing',
  GATE_ACTION_CATEGORY = 'gateActionCategory',
  GATE_ACTION = 'gateAction',
  GATE_ACTION_LISTING_TAB = 'gateActionListingTab',
  SOLUTION_CONDITION_LISTING_TAB = 'solutionConditionListingTab',
  SOLUTION_REFERENCE_LISTING_TAB = 'solutionReferenceListingTab',
}

export interface IAdminService {
  getList(pagination: IPagination): Observable<IPagingResponse<IGeneralItem>>; //get list of items with pagination
  getAllItems(): Observable<IGeneralItem[]>; // get all items without pagination
  getItemDetail(selectedId: string): Observable<IGeneralItem>;
  addItem(item: IGeneralItem): Observable<IGeneralItem>;
  updateItem(item: IGeneralItem): Observable<IGeneralItem>;
  deleteItem(id: string): Observable<IGeneralItem>;
  orderItems(items: IOrderByItem[]): Observable<IGeneralItem[]>;
}

export interface IAdminState<T> {
  items: T[];
  itemDetail: T | undefined;
  isLoading: boolean;
  query: string;
  modalStatus?: ModalStatus;
}

export type ModalStatus = 'loading' | 'success' | 'error' | 'none' | 'delete';

export type SortOrder = 'asc' | 'desc' | 'none';

export const ADMIN_DIALOG_HEADER: { [key in AdminFeature]: string } = {
  [AdminFeature.CHALLENGE_MANAGEMENT]: 'Challenge',
  [AdminFeature.ENTRY_CATEGORY_MANAGEMENT]: 'Data Entry Category',
  [AdminFeature.ENTRY_VALUE_MANAGEMENT]: 'Data Entry Value',
  [AdminFeature.ROLE_MANAGEMENT]: 'Role',
  [AdminFeature.SESSION_MANAGEMENT]: 'Session',
  [AdminFeature.SOLUTION_MANAGEMENT]: 'Solution',
  [AdminFeature.SUB_CHALLENGE_MANAGEMENT]: 'Sub-Challenge',
  [AdminFeature.PSL_MANAGEMENT]: 'PSL',
  [AdminFeature.SOLUTION_CATEGORY]: 'Solution Category',
  [AdminFeature.SOLUTION_RELEVANCE_LISTING]: 'Solution Relevance Listing',
  [AdminFeature.SOLUTION_COMPATIBILITY]: 'Solution Compatibility Listing',
  [AdminFeature.ENTRY_JOB_PHASE_MANAGEMENT]: 'Data Entry Jog Phase',
  [AdminFeature.DATA_ENTRY_COMPATIBILITY]: 'Data Entry Compatibility',
  [AdminFeature.REFERENCE]: 'Reference',
  [AdminFeature.REFERENCE_CATEGORY]: 'Reference Category',
  [AdminFeature.SOLUTION_RERERENCE_LISTING]: 'Solution Reference Listing',
  [AdminFeature.GATE_ACTION_CATEGORY]: 'Gate Action Category',
  [AdminFeature.GATE_ACTION]: 'Gate Action',
  [AdminFeature.SOLUTION_CONDITION]: 'Solution Condition',
  [AdminFeature.GATE_ACTION_LISTING_TAB]: 'Gate Action Listing Tab',
  [AdminFeature.SOLUTION_CONDITION_LISTING_TAB]:
    'Solution Condition Listing Tab',
  [AdminFeature.SOLUTION_REFERENCE_LISTING_TAB]:
    'Solution Reference Listing Tab',
};

export const ADMIN_DIALOG_MESSAGE: { [key in AdminFeature]: string } = {
  [AdminFeature.CHALLENGE_MANAGEMENT]: 'Challenge',
  [AdminFeature.ENTRY_VALUE_MANAGEMENT]: 'Data Entry Value',
  [AdminFeature.ENTRY_CATEGORY_MANAGEMENT]: 'Data Entry Category',
  [AdminFeature.ROLE_MANAGEMENT]: 'Role',
  [AdminFeature.SESSION_MANAGEMENT]: 'Session',
  [AdminFeature.SOLUTION_MANAGEMENT]: 'Solution',
  [AdminFeature.SUB_CHALLENGE_MANAGEMENT]: 'Sub-Challenge',
  [AdminFeature.PSL_MANAGEMENT]: 'PSL',
  [AdminFeature.SOLUTION_CATEGORY]: 'Solution Category',
  [AdminFeature.SOLUTION_RELEVANCE_LISTING]: 'Solution Relevance Listing',
  [AdminFeature.SOLUTION_COMPATIBILITY]: 'Solution Compatibility Listing',
  [AdminFeature.ENTRY_JOB_PHASE_MANAGEMENT]: 'Data Entry Jog Phase',
  [AdminFeature.DATA_ENTRY_COMPATIBILITY]: 'Data Entry Compatibility',
  [AdminFeature.REFERENCE]: 'Reference',
  [AdminFeature.REFERENCE_CATEGORY]: 'Reference Category',
  [AdminFeature.SOLUTION_RERERENCE_LISTING]: 'Solution Reference Listing',
  [AdminFeature.GATE_ACTION_CATEGORY]: 'Gate Action Category',
  [AdminFeature.GATE_ACTION]: 'Gate Action',
  [AdminFeature.SOLUTION_CONDITION]: 'Solution Condition',
  [AdminFeature.GATE_ACTION_LISTING_TAB]: 'Gate Action Listing Tab',
  [AdminFeature.SOLUTION_CONDITION_LISTING_TAB]:
    'Solution Condition Listing Tab',
  [AdminFeature.SOLUTION_REFERENCE_LISTING_TAB]:
    'Solution Reference Listing Tab',
};

export const FIELDS_TO_SEARCH: { [key in AdminFeature]: string[] } = {
  [AdminFeature.CHALLENGE_MANAGEMENT]: ['name', 'pslNames', 'state'],
  [AdminFeature.ENTRY_CATEGORY_MANAGEMENT]: [
    'name',
    'description',
    'inputTypeName',
    'solutionConditionName',
  ],
  [AdminFeature.ENTRY_VALUE_MANAGEMENT]: [
    'name',
    'description',
    'dataEntryCategoryName',
    'state',
  ],
  [AdminFeature.ROLE_MANAGEMENT]: [],
  [AdminFeature.SESSION_MANAGEMENT]: [
    'id',
    'exernalId',
    'modifiedAt',
    'modifiedBy',
    'diagnosisStatusName',
    'selectedChallenges',
  ],
  [AdminFeature.SOLUTION_MANAGEMENT]: [
    'name',
    'solutionCategoryName',
    'description',
    'state',
  ],
  [AdminFeature.SUB_CHALLENGE_MANAGEMENT]: [
    'name',
    'challengeName',
    'pslNames',
    'state',
  ],
  [AdminFeature.PSL_MANAGEMENT]: ['name', 'description', 'state'],
  [AdminFeature.SOLUTION_CATEGORY]: ['name', 'description', 'solutionNames'],
  [AdminFeature.ENTRY_JOB_PHASE_MANAGEMENT]: [
    'subChallengeName',
    'subChallengeDescription',
    'description',
    'jobPhaseName',
    'description',
  ],
  [AdminFeature.SOLUTION_RELEVANCE_LISTING]: [
    'solutionName',
    'solutionDescription',
    'relevanceDescription',
  ],
  [AdminFeature.SOLUTION_COMPATIBILITY]: [
    'compatibleSolutionName',
    'compatibleSolutionDescription',
    'compatibilityValueName',
    'message',
    'description',
  ],
  [AdminFeature.DATA_ENTRY_COMPATIBILITY]: [
    'compatibleSolutionName',
    'compatibleSolutionDescription',
    'compatibilityValueName',
    'message',
    'description',
  ],
  [AdminFeature.REFERENCE_CATEGORY]: ['name', 'description', 'referenceNames'],
  [AdminFeature.REFERENCE]: [
    'name',
    'referenceCategoryName',
    'hmsNumber',
    'documentLink',
    'description',
  ],
  [AdminFeature.SOLUTION_RERERENCE_LISTING]: ['solutionName', 'description'],
  [AdminFeature.GATE_ACTION_CATEGORY]: [
    'name',
    'description',
    'gateActionNames',
  ],
  [AdminFeature.GATE_ACTION]: ['name', 'description', 'gateActionCategoryName'],
  [AdminFeature.GATE_ACTION_LISTING_TAB]: ['gateActionName', 'description'],
  [AdminFeature.SOLUTION_CONDITION]: ['name', 'description', 'uoMCategoryName'],
  [AdminFeature.SOLUTION_CONDITION_LISTING_TAB]: [
    'solutionConditionName',
    'minValue',
    'maxValue',
    'uoMName',
    'description',
  ],
  [AdminFeature.SOLUTION_REFERENCE_LISTING_TAB]: [
    'name',
    'referenceCategoryName',
    'hmsNumber',
    'description',
  ],
};

export const searchItemsByFields = <T extends IGeneralItem>(
  items: T[],
  fieldsToSearch: string[],
  searchTerm: string,
) => {
  const filteredItems = items.filter((item) => {
    const contentToSearch = fieldsToSearch
      .map((field) =>
        item[field as keyof T] instanceof Date
          ? item[field as keyof T]?.toString()
          : item[field as keyof T],
      )
      .join('');
    return contentToSearch.toLowerCase().includes(searchTerm.toLowerCase());
  });
  return filteredItems;
};

export interface IOrderByItem {
  itemId: string;
  order: number;
}

export type DialogMode = 'view' | 'edit';

export type AdminStoreType =
  | ChallengeStoreType
  | SubChallengeStoreType
  | SessionStoreType
  | SolutionCategoryStoreType
  | SolutionManagementStoreType
  | RoleStoreType
  | PslStoreType
  | EntryValueStoreType
  | EntryCategoryStoreType
  | ReferenceCategoryStoreType
  | ReferenceStoreType
  | GateActionCategoryStoreType
  | GateActionStoreType
  | SolutionConditionStoreType;
