import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProgressSpinner } from 'primeng/progressspinner';

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [ProgressSpinner],
  template: ` <div
    class="absolute w-full h-full top-0 left-0 right-0 flex align-items-center justify-content-center bg-white-alpha-70 z-5"
  >
    <p-progressSpinner ariaLabel="loading" class="center-flex" />
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {}
