<div class="container">
  <div class="selected-challenges">
    <h2 class="selected-challenges_header">{{ title.SelectedChallenges }}</h2>
    <div class="selected-challenges-wrapper">
      @for(pairChallenge of selectedChallenges(); track pairChallenge.challengeName){
      <div class="selected-challenges-content">
        <span>{{pairChallenge.challengeName}} - {{pairChallenge.subChallengeName}}</span>
      </div>
      }
    </div>
  </div>

  <div class="input">
    <div class="input_title">
      <h2>{{ title.Inputs }}</h2>
    </div>

    <form [formGroup]="inputSolution" class="input-form">
      @for (fieldKey of dropdownOrder; track fieldKey) { @if (dropdownOptions[fieldKey]) {
      <div class="input-solution" [ngClass]="{'input-temperature-solution': isDownholeTemperature(fieldKey)}">
        <label [for]="fieldKey">{{ fieldKey }}:</label>

        @if (isDownholeTemperature(fieldKey)) {
        <div class="input-container">
          <input
            type="number"
            [disabled]="isDisabled()"
            [formControlName]="fieldKey"
            pInputText
            placeholder="Input number"
            class="input-solution_field"
            [ngClass]="{'disabled-dropdown': isDisabled()}"
          />
        </div>
        }
        <p-dropdown
          [disabled]="isDisabled()"
          [formControlName]="fieldKey"
          [options]="dropdownOptions[fieldKey]"
          [placeholder]="isDownholeTemperature(fieldKey) ? 'C' : 'Select'"
          class="input-solution_dropdown"
          [ngClass]="{
            'temperature-dropdown': isDownholeTemperature(fieldKey),
            'disabled-dropdown': isDisabled()
          }"
        ></p-dropdown>
      </div>
      } }
    </form>
  </div>
</div>
