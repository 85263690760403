<div class="container">
  <app-review-selected-challanges></app-review-selected-challanges>

  <div class="input">
    <div class="input_title inline-block">
      <h2>{{ title.Inputs }}</h2>
    </div>

    <div class="clear-all-wrapper inline-block">
      <p-button
        class="clear-all-button"
        label="Clear All"
        (onClick)="clearAllSelections()"
        [ngClass]="{'clear-btn-disabled': !isEnableClearAllBtn()}"
        [disabled]="!isEnableClearAllBtn()"
      />
    </div>

    <form [formGroup]="inputSolution" class="input-form">
      @for (cate of entryCategories; track cate) { @if (requiredInputs[cate.name]) { @let isInputControlValid =
      isInputControl(cate.inputType.uiControlName!);

      <div class="input-solution" [ngClass]="{'input-temperature-solution': isInputControlValid}">
        <label [for]="cate">{{ cate.name }}:</label>
        @if (isInputControlValid) {
        <div class="input-container">
          <input
            type="number"
            formControlName="input{{cate.name}}"
            pInputText
            placeholder="Input number"
            class="input-solution_field"
            [ngClass]="{'disabled-dropdown': isDisabledDropdown()}"
            (change)="onTextInputChanged($event, cate)"
          />
        </div>
        }
        <p-dropdown
          appendTo="body"
          [formControlName]="cate.name"
          [options]="requiredInputs[cate.name]"
          [placeholder]="getDefaultValue(cate)"
          class="input-solution_dropdown"
          (onChange)="onDropdownChanged($event, cate)"
          [ngClass]="{
            'temperature-dropdown': isInputControlValid,
            'disabled-dropdown': isDisabledDropdown()
          }"
          [showClear]="isInputControlValid ? false :  true"
        />
      </div>
      } }
    </form>
  </div>
</div>
