import { createAction, props } from '@ngrx/store';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';

export const setChallenges = createAction(
  '[Challenges] Set Selected Challenges',
  props<{
    pairChallenges: PairChallenge[];
  }>(),
);

export const setScreenStates = createAction(
  '[Challenges] Set Screen States',
  props<{
    isInitializeDiagnosisScreen: boolean;
  }>(),
);

export const setSessionId = createAction(
  '[Challenges] Set Diagnosis Session Identity',
  props<{
    diagnosiSessionId: string;
  }>(),
);

export const setExternalId = createAction(
  '[Challenges] Set Diagnosis External Identity',
  props<{
    diagnosiExternalId: string;
  }>(),
);
