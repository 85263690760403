import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ImageModule } from 'primeng/image';
import { StepsModule } from 'primeng/steps';
import { STEPPER_ITEMS } from './step-master.constant';

@Component({
  selector: 'app-step-master',
  standalone: true,
  imports: [ImageModule, StepsModule],
  templateUrl: './step-master.component.html',
  styleUrl: './step-master.component.scss',
})
export class StepMasterComponent implements OnInit {
  initialStepper = signal<MenuItem[]>(STEPPER_ITEMS).asReadonly();
  currentRoute = signal<string>('');

  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    // Subscribe to route changes
    this.#router.events
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.currentRoute.set(this.#router.url);
        this.updateStepperStyles();
      });

    // Initialize styles on component load
    this.currentRoute.set(this.#router.url);
    this.updateStepperStyles();
  }

  updateStepperStyles(): void {
    let currentIndex = -1;
    this.initialStepper().forEach((item, index) => {
      if (this.currentRoute().includes(item.routerLink)) {
        currentIndex = index;
      }
    });
    this.initialStepper().forEach((item, index) => {
      item.styleClass = index <= currentIndex ? 'highlight-step' : '';
    });
  }
}
