<div class="container">
  <div class="solution">
    <div class="solution-title inline-block">
      <h2 class="solution_title">{{ title }}</h2>
    </div>

    @if (store.isEmptyAvailableSoltions()) {
    <div class="solution-empty">
      <h3 class="no-data">No Data yet</h3>
      <span>Once required inputs are filled, you will see data here</span>
    </div>
    } @else {
    <div class="solution-unselect-btn clear-all-wrapper inline-block">
      <p-button
        class="unselect-btn"
        label="Unselect All"
        (onClick)="unselectAllSolutions()"
        [disabled]="!store.isEnableUnselectAllBtn()"
        [ngClass]="{'unselect-btn-disabled': !store.isEnableUnselectAllBtn()}"
      />
    </div>
    }

    <ng-container>
      <ng-container *ngTemplateOutlet="availableSolutions; context: { solution: store.filteredSolutionsByCategory() }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #availableSolutions let-solution="solution">
  <div class="solution-wrapper">
    @for(solution of store.filteredSolutionsByCategory(); track solution.category) {
    <div class="solution-block">
      <div class="solution-header">
        <h3 class="solution-block_header inline-block">{{ solution.category }}</h3>
      </div>
      @for (item of solution.compatibilities; track item.availableSolution.name) {
      <div class="solution-item" [ngClass]="{ 'selected-solution': item.isChecked }">
        @let isRelevantTag = item.compatibilityValue?.name === compatibilityValueTypes.NotRelevant;
        <div class="image-container" [ngClass]="{'solution-disabled': isNotCompatible(item)}">
          <img src="assets/halliburton_select_solution.jpg" alt="solution-img" class="solution-item_img" />
          @if (!isNotCompatible(item)) {
          <div class="circle-overlay center-flex">
            <input
              type="checkbox"
              id="checkbox"
              class="circle-overlay-front center-flex"
              (change)="onCheckboxChange(item)"
              [checked]="item.isChecked"
            />
          </div>
          }
        </div>
        <h4 class="solution-item_name" [title]="item.availableSolution.name">{{ item.availableSolution.name }}</h4>
        @if (item.compatibilityValue) {
        <div
          class="solution-item_tag"
          [ngStyle]="{'background-color': item.compatibilityValue.hexColor}"
          [ngClass]="{'not-relevant-tag': isRelevantTag}"
        >
          <i [ngClass]="getSolutionIcon(item)" [title]="this.formatSolutionMessages(item.message)" tooltipPosition="bottom"></i>
          @if (!isRelevantTag) {
          <h3 class="tag-name">{{ item.compatibilityValue.name }}</h3>
          }
        </div>
        }
      </div>
      }
    </div>
    }
  </div>
</ng-template>
