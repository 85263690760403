import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Directive({
  selector: '[appLoading]',
  standalone: true,
})
export class LoadingDirective {
  private _loading: boolean = false;
  private componentRef: ComponentRef<LoadingSpinnerComponent> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @Input()
  set appLoading(value: boolean) {
    this._loading = value;
    if (this._loading) {
      this.appendLoadingComponent();
    } else {
      this.removeLoadingComponent();
    }
  }

  get appLoading(): boolean {
    return this.appLoading;
  }

  private appendLoadingComponent() {
    if (!this.componentRef) {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
      this.componentRef = this.viewContainerRef.createComponent(
        LoadingSpinnerComponent,
      );
      this.renderer.appendChild(
        this.el.nativeElement,
        this.componentRef.location.nativeElement,
      );
    }
  }

  private removeLoadingComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
      this.renderer.removeStyle(this.el.nativeElement, 'position');
    }
  }
}
