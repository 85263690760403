import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { apiConfig } from 'src/app/api.config';
import { environment } from 'src/environments/environment';
import { IArrayResponse } from '../common/models/general.model';
import { DataEntryCategory } from '../common/models/master-data/data-entry';
import {
  DiagnosisDataEntry,
  DiagnosisDataEntryCreateDto,
  DiagnosisDataEntryGetDto,
} from '../common/models/workflow/diagnosis-entry';
import {
  DiagnosisSolutionCompatibility,
  UoMConversionValue,
} from '../common/models/workflow/diagnosis-solution';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisDataEntryService {
  private http = inject(HttpClient);
  private baseUrl = environment.api;

  upsertEntryValue(entryValue: DiagnosisDataEntryCreateDto) {
    return this.http.post<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl + apiConfig.diagnosisDataEntry.upsertEntryValue,
      entryValue,
    );
  }

  changeUoM(entryValue: DiagnosisDataEntryCreateDto) {
    return this.http.post<UoMConversionValue>(
      this.baseUrl + apiConfig.diagnosisDataEntry.changeUoM,
      entryValue,
    );
  }

  removeEntryValue(entryValue: DiagnosisDataEntryGetDto) {
    return this.http.post<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl + apiConfig.diagnosisDataEntry.removeEntryValue,
      entryValue,
    );
  }

  resetEntryValue(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisSolutionCompatibility>>(
      this.baseUrl +
        apiConfig.diagnosisDataEntry.resetEntryValue.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getEnteredValues(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisDataEntry>>(
      this.baseUrl +
        apiConfig.diagnosisDataEntry.getEnteredValues.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  verifyEnteredValues(sessionId: string) {
    return this.http.get<IArrayResponse<DiagnosisDataEntry>>(
      this.baseUrl +
        apiConfig.diagnosisDataEntry.verifyEnteredValues.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }

  getSuggestedDataEntryCategories(sessionId: string) {
    return this.http.get<IArrayResponse<DataEntryCategory>>(
      this.baseUrl +
        apiConfig.diagnosisDataEntry.getSuggestedDataEntryCategories.replace(
          ':sessionId',
          sessionId,
        ),
    );
  }
}
