import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PairChallenge } from 'src/app/views/select-challenge/components/select-challenges-container/challenges-sub-challenges/challenges-sub-challenges.model';
import { DiagnosisSolutionCompatibility } from '../../common/models/workflow/diagnosis-solution';
import { setAllRequiredCheckboxesSelected } from '../../store/actions/required-action.actions';
import {
  setChallenges,
  setExternalId,
  setSessionId,
} from '../../store/actions/select-challenge.actions';
import {
  setInputFields,
  setIsAvailableSolutionSelected,
} from '../../store/actions/select-solution.action';
import {
  getAllRequiredCheckboxesSelected,
  getIsDesignApproved,
} from '../../store/selectors/required-action.selector';
import {
  selectExernalId,
  selectPairChallenge,
  selectSessionId,
} from '../../store/selectors/select-challenge.selectors';
import {
  getAvailableSolutions,
  selectIsAvailableSolutionSelected,
} from '../../store/selectors/select-solution.selectors';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  readonly #globalStore = inject(Store);
  readonly #destroyRef = inject(DestroyRef);

  getSessionId(): Observable<string> {
    return this.#globalStore
      .select(selectSessionId)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getExternalId(): Observable<string> {
    return this.#globalStore
      .select(selectExernalId)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getPairChallenge(): Observable<PairChallenge[]> {
    return this.#globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getIsDesignApproved(): Observable<boolean> {
    return this.#globalStore
      .select(getIsDesignApproved)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getAvailableSolutions(): Observable<DiagnosisSolutionCompatibility[]> {
    return this.#globalStore
      .select(getAvailableSolutions)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getRequiredCheckboxSelected(): Observable<boolean> {
    return this.#globalStore
      .select(getAllRequiredCheckboxesSelected)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  getSelectedSolution(): Observable<boolean> {
    return this.#globalStore
      .select(selectIsAvailableSolutionSelected)
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }

  dispatchSelectedSolution(isSelected: boolean): void {
    this.#globalStore.dispatch(
      setIsAvailableSolutionSelected({
        isSelectedSolutions: isSelected,
      }),
    );
  }

  dispatchRequiredCheckboxSelected(allChecked: boolean): void {
    this.#globalStore.dispatch(
      setAllRequiredCheckboxesSelected({
        areAllRequiredCheckboxesSelected: allChecked,
      }),
    );
  }

  dispatchExternalId(externalId: string): void {
    this.#globalStore.dispatch(
      setExternalId({
        diagnosiExternalId: externalId,
      }),
    );
  }

  dispatchSessionId(sessionId: string): void {
    this.#globalStore.dispatch(
      setSessionId({
        diagnosiSessionId: sessionId,
      }),
    );
  }

  dispatchPairChallenge(pairChallenges: PairChallenge[]): void {
    this.#globalStore.dispatch(
      setChallenges({
        pairChallenges,
      }),
    );
  }

  dispatchInputFields(isAllFieldSelected: boolean): void {
    this.#globalStore.dispatch(
      setInputFields({
        isAllFieldSelected,
      }),
    );
  }
}
