import { NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ReviewSelectedSolutionsStore } from './review-selected-solutions.store';

@Component({
  selector: 'app-review-selected-solutions',
  standalone: true,
  imports: [NgStyle, ProgressSpinnerModule],
  providers: [ReviewSelectedSolutionsStore],
  templateUrl: './review-selected-solutions.component.html',
  styleUrl: './review-selected-solutions.component.scss',
})
export class ReviewSelectedSolutionsComponent {
  readonly store = inject(ReviewSelectedSolutionsStore);
}
