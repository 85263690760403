import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, provideZoneChangeDetection } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouterOutlet } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { StoreModule } from '@ngrx/store';
import { HeaderComponent } from 'src/app/core/common/components/header/header.component';
import { StepMasterComponent } from 'src/app/core/common/components/step-master/step-master.component';
import { rootReducer } from 'src/app/core/store/reducers/root.reducer';
import { ChallengesSubChallengesComponent } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.component';
import { SelectedPairChallengesComponent } from 'src/app/views/select-challenge/components/selected-pair-challenges/selected-pair-challenges.component';
import { AvailableSolutionsComponent } from 'src/app/views/select-solutions/components/available-solutions/available-solutions.component';
import { InputSolutionsComponent } from 'src/app/views/select-solutions/components/input-solutions/input-solutions.component';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule, routes } from './app.routes';
import { MsalServiceInitializer } from './core/services/msal-initializer.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.api, environment.scopes as any);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.scopes],
    },
    loginFailedRoute: '/',
  };
}
@NgModule({
  imports: [
    BrowserModule,
    StoreModule.forRoot(rootReducer),
    RouterOutlet,
    AppRoutingModule,
    SelectedPairChallengesComponent,
    ChallengesSubChallengesComponent,
    StepMasterComponent,
    HeaderComponent,
    AvailableSolutionsComponent,
    InputSolutionsComponent,
  ],
  declarations: [AppComponent],
  providers: [
    MsalServiceInitializer,
    {
      provide: MSAL_INSTANCE,
      useFactory: (initializer: MsalServiceInitializer) => {
        return initializer.getInstance();
      },
      deps: [MsalServiceInitializer],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(routes),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
